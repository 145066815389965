<div *ngIf='documentDetailsDto'>
    <article class="dialog__header">
        <h3 mat-dialog-title>Document Details</h3>
        <img (click)="closeDialog()" class="dialog__header-icon" src="assets/icons/icon-close-dark.svg"
            alt="close icon" />
    </article>
    <mat-dialog-content class="mat-typography mat-dialog-content">
        <div class="view-documents-text">
            <span>View document details below.</span>
        </div>
        <div class="main-content">
            <!-- LEFT COLUMN -->
            <div class="left-column">
                <div class="identify-document">
                    <div class="heading">
                        <span>1. Identify Document</span>
                    </div>
                    <div class="radio-group">
                        <mat-radio-group class="ig2" required [(ngModel)]="selectedIgniteDocumentName" [disabled]="true"
                            (change)="onSelectDocument()">
                            <ng-container *ngFor="let document of documentNames">
                                <mat-radio-button [value]="document">
                                    {{document.documentName}}
                                </mat-radio-button>
                                <br>
                            </ng-container>
                        </mat-radio-group>
                    </div>
                </div>
                <div class=" identify-document-type-details">
                    <div class="heading">
                        <span>2. Identify Document Type and Details </span>
                    </div>
                    <div class="doc-inputs">
                        <div class="row-one dropdowns">
                            <model-filter-select class="ig2 first-fropdown" label="Document Type" [options]="imageTypes"
                                nameField="description" [(selectedValue)]="selectedDocumentTypeId"
                                placeholder="Document Type" [required]="true"
                                (selectionChange)="documentTypeSelectionChange()" [disabled]="true">
                            </model-filter-select>
                            <model-filter-select
                                *ngIf="!(selectedDocumentType?.id === 154 || selectedDocumentType?.id === 126)"
                                class="ig2" label="Document Name" placeholder="Document Name"
                                [options]="selectedDocumentType ? selectedDocumentType.imageNames : []"
                                idField="description" nameField="description"
                                [(selectedValue)]="selectedDocumentNameDescription" [required]="true"
                                (selectionChange)="documentNameSelectionChange()" [disabled]="true">
                            </model-filter-select>
                            <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill" [disabled]="true"
                                *ngIf="selectedDocumentType?.id === 154 || selectedDocumentType?.id === 126">
                                <mat-label class="mat-label-wrap">Document Name</mat-label>
                                <input matInput [(ngModel)]="selectedDocumentNameDescription" [required]="true"
                                    [maxlength]="40" [disabled]="true">
                            </mat-form-field>
                        </div>
                        <div class="row-two">
                            <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
                                <mat-label class="mat-label-wrap">Append (optional)</mat-label>
                                <input class="mat-input-element-show-placeholder" matInput [(ngModel)]="append"
                                    [disabled]="true" [maxlength]="40" />
                            </mat-form-field>
                        </div>
                        <div class="row-three">
                            <model-filter-select class="ig2" label="Action (optional)" [options]="imageTriggers"
                                placeholder="Image Trigger Name" [disabled]="true" nameField="description"
                                [(selectedValue)]="selectedActionId">
                            </model-filter-select>
                        </div>
                        <div class="row-four">
                            <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
                                <mat-label class="mat-label-wrap">Notes (optional)</mat-label>
                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"
                                    [disabled]="true" placeholder="Enter notes here" [(ngModel)]="notes"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-column">
                <div class="doc-div">
                    <div class="doc-name-label">
                        <span>Document Name</span>
                    </div>
                    <div class="doc-name">
                        <div class="icon">
                            <lib-icon
                                [name]="isMsg ? 'mail' : 'file_'+ documentDetailsDto.imageFileExtensionType.description.split('.')[documentDetailsDto.imageFileExtensionType.description.split('.').length - 1].toLowerCase()">
                            </lib-icon>
                        </div>
                        <div class="name">
                            <p>{{append.length > 0 ? (selectedDocumentNameDescription ? selectedDocumentNameDescription
                                :
                                '') + ' ' + append.trim() :
                                selectedDocumentNameDescription}}{{documentDetailsDto.imageFileExtensionType.description.toLowerCase()}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="doc-details">
                    <div class="detail">
                        <div class="caption">
                            <span>Uploaded</span>
                        </div>
                        <div class="data">
                            <span>{{documentDetailsDto.createdDate | convertTime: "us/pacific": timezone.value :
                                "default" : true : true }} by {{documentDetailsDto.createdBy}}</span>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="caption">
                            <span>Action</span>
                        </div>
                        <div class="data">
                            <span>None </span>
                            <!-- <span>Some Action</span> -->
                        </div>
                    </div>
                    <div class="detail">
                        <div class="caption">
                            <span>Size</span>
                        </div>
                        <div class="data">
                            <span>{{formatBytes(documentDetailsDto.imageSize)}} MB</span>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="caption">
                            <span>Pages</span>
                        </div>
                        <div class="data">
                            <span>{{documentDetailsDto.pageCount}} </span>
                        </div>
                    </div>

                    <div class="detail">
                        <div class="caption">
                            <span>File Type</span>
                        </div>
                        <div class="data">
                            <span>
                                {{documentDetailsDto.imageFileExtensionType.description.split('.')[documentDetailsDto.imageFileExtensionType.description.split('.').length
                                - 1]}}
                            </span>
                            <!-- <span>pdf</span> -->
                        </div>
                    </div>
                    <div class="detail">
                        <div class="caption">
                            <span>Electronic File ID</span>
                        </div>
                        <div class="data">
                            <span> {{documentDetailsDto?.id}}</span>
                            <!-- <span>2686946</span> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mat-dialog-actions">
        <button class="ig2 button button--no-border button--small" mat-button mat-dialog-close>Cancel</button>
        <button class="ig2 button button--primary button--small" mat-button (click)="closeDialog()">
            Close
        </button>
    </mat-dialog-actions>
</div>