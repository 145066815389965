import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor as Interceptor,
  HttpHeaders
} from '@angular/common/http';
import { Router } from '@angular/router';
import { getToken, getUser } from '@igps/client-root-config';
import { v4 as uuidv4 } from 'uuid';
import { catchError, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { LoggerService } from '../services/logger.service';
import { ErrorModel } from 'src/app/models';

@Injectable()
export class HttpInterceptor implements Interceptor {

  constructor(private router: Router, private loggerService: LoggerService) {}
  correlationId = uuidv4();
  handleError(error: any, errorType = "UI"): void {
    const errorInfo: ErrorModel = {
      applicationName: "IGPS-Client=Processor-TaskDetail",
      loggedInUserName: getUser(),
      error: errorType === "UI" ? error?.stack : error?.message,
      url: this.router?.url
    }
    this.loggerService.logApplicationErrors(errorInfo).subscribe();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next)).pipe(
      catchError((err) => {
        if (!err?.url?.endsWith('log/v1')) {
          this.handleError(err, "API");
        }
        return throwError(()=>{
          return err;
        });
      }));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await getToken();
    let headers: HttpHeaders;
    if(req.url.includes('s3.amazonaws.com')){
      if(req.method !== 'GET'){
        headers = new HttpHeaders({
          'x-amz-server-side-encryption': 'AES256'
        });
      }
      else{
        headers = new HttpHeaders();
      }
    }
    else{
      headers = new HttpHeaders({
        'Cache-control': 'no-cache',
        Expires: '0',
        Pragma: 'no-cache',
        originapp: 'client',
        'Correlation-Id': this.correlationId,
        Authorization: `Bearer ${token}`,
      });
    }
    if (req.body !== FormData) {
      headers.set('Content-Type', 'application/json');
    }
    const authReq = req.clone({
      headers,
    });
    return lastValueFrom(next.handle(req.clone(authReq)));
  }
}
