<article class="dialog__header">
    <h3 mat-dialog-title>Attach Email</h3>
    <!-- refactor -->
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>
<mat-dialog-content class="mat-typography ignite-two attachemail">
    <div>
        <span class="dialog__subheader u-mb-8">Select attachments to merge with the email</span>
    </div>
    <div class="checkbox-wrapper row-seven">
        <div class="label-input-labelwrapper" *ngIf="validFileTypes.length>0">
            <mat-checkbox class="ig2" [checked]="isChecked()" [indeterminate]="isIndeterminate()"
                (change)="$event ? toggleAll($event) : null" [disabled]="selectedInvalidAttachment.length>0?true:false">
                <span class="emailattach">Select
                    All</span>&nbsp; <lib-badge [content]="validFileTypes.length"></lib-badge></mat-checkbox>
        </div>
        <section>
            <div class="label-input-labelwrapper">
                <mat-checkbox class="ig2" (click)="$event.stopPropagation()" id="includeEmail"
                    (change)="$event ? toggle('includeEmail', $event) : null" [checked]="includeEmail"
                    [disabled]="selectedInvalidAttachment.length>0?true:false">
                    <div class="doc-name">
                        <div class="docnameList">
                            <div class="icon">
                                <lib-icon name="mail">
                                </lib-icon>
                            </div>
                            <div class="name">
                                <p>Include Email</p>
                                <p class="ptext">Select this item to include the email body/subject/to/from/date in
                                    your merged pdf</p>
                            </div>
                        </div>


                    </div>
                </mat-checkbox>
            </div>
            <div class="label-input-labelwrapper" *ngFor="let x of validFileTypes">
                <mat-checkbox class="ig2" (click)="$event.stopPropagation()"
                    [disabled]="selectedInvalidAttachment.length>0?true:false"
                    (change)="$event ? toggle(x, $event) : null" [checked]="exists(x)">
                    <div class="doc-name">
                        <div class="docnameList">
                            <div class="icon">
                                <lib-icon
                                    [name]="'file_'+ x.name.split('.')[x.name.split('.').length - 1].toLowerCase()">
                                </lib-icon>
                            </div>
                            <div class="name">
                                <p>{{x.name}}</p>
                                <p class="ptext">{{formatBytes(x.size)}}</p>
                            </div>
                        </div>


                    </div>
                </mat-checkbox>
            </div>
        </section>
        <section *ngIf="inValidFileTypes && inValidFileTypes.length>0">
            <div class="invalidType">
                <lib-icon name="info">
                </lib-icon>
                <p class="dialog__subheader u-mb-8">Files below cannot be merged with the pdf. This functionality
                    is
                    unavailable at this time.
                </p>
            </div>
            <div class="igps-padding">
                <div>
                    <span class="dialog__subheader u-mb-8">Select an attachment to add to the Doc Repo</span>
                    &nbsp;<lib-badge [content]="inValidFileTypes.length"></lib-badge>
                </div>
            </div>
        </section>
        <section>
            <div class="label-input-labelwrapper" *ngFor="let x of inValidFileTypes">
                <mat-checkbox class=" ig2" [disabled]="selectedValidAttachments.length>0?true:false"
                    [checked]="validexist(x)" (change)="$event ? getSelectedAttachment(x, $event) : null">
                    <div class="doc-name">
                        <div class="docnameList">
                            <div class="icon">
                                <lib-icon *ngIf="x.contentType!==''"
                                    [name]="'file_'+ x.name.split('.')[x.name.split('.').length - 1].toLowerCase()">
                                </lib-icon>
                                <lib-icon name="mail" *ngIf="x.contentType==''">
                                </lib-icon>
                            </div>
                            <div class="name">
                                <p>{{x.name}}</p>
                                <p class="ptext">{{formatBytes(x.size)}}</p>
                            </div>
                        </div>


                    </div>
                </mat-checkbox>
            </div>
        </section>

    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="ig2 button button--no-border button--small" mat-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button
        class="btn-submit ig2 button button--primary button--small mat-button mat-button-base btn-submit"
        cdkFocusInitial (click)="AttachDocument()"
        [disabled]="(selectedValidAttachments.length>0 ||selectedInvalidAttachment.length>0)?'false':'true'">Attach</button>
</mat-dialog-actions>