export interface TaskBasicInfoDataModel {
  fastFileId: number;
  id: string;
  externalSystemTaskId: number;
  branchName: string;
  branchOfficerName: string;
  branchOfficerType: string;
  transcationType: string;
  settlementDate: string;
  dueDateUtc: string;
  orderedDateUtc: string;
  fastFileNumber: string;
  fileStatus: Status;
  taskStatus: Status;
  assignee: Assignee;
  processId: string;
  processName: string;
  sourceId: Source;
  emailId?: string | null;
  internalGroupId : string;
}

export enum Source {
  IgnitePS = 1,
  Ignite = 2
}

export interface Assignee {
  id: string;
  name: string;
  role: string;
}

export interface Status {
  id: number;
  value: string;
  description: string;
}
