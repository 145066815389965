<article class="dialog__header">
  <h3 mat-dialog-title>{{ noteHeader }}</h3>
  <!-- refactor -->
  <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>

<mat-dialog-content class="mat-typography ignite-two">
  <p class="dialog__subheader u-mb-8">Type your note below.</p>
  <div>
    <mat-label class="label-color">Note</mat-label>
    <div
      contenteditable="true"
      class="ig2"
      placeholder="Enter note here"
      #divValue
      (keydown)="maxLengthLimit($event)"
      (paste)="checkForMaxLengthPastedChars($event)"
      (input)="notesData = $any($event.target).innerText"></div>
    <p>{{ notesData ? maxLengthAllowedChars - notesData.length : maxLengthAllowedChars }} character is remaining</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="ig2 button button--no-border button--small" mat-button mat-dialog-close>Cancel</button>
  <button
    *ngIf="noteHeader === 'Add Note'"
    class="ig2 button button--primary button--small"
    mat-button
    [mat-dialog-close]="true"
    (click)="saveNotes()"
    [disabled]="disbaleSaveBtn || notesData === ''"
    cdkFocusInitial>
    Save
  </button>
</mat-dialog-actions>

