import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { finalize } from "rxjs";
import { showSpinnerEvent, showToastEvent } from "../../../app.component";
import { DocumentService } from "../../../services/document.service";
import { ReloadDocumentListEvent } from "../doc-upload/doc-upload.component";
import { selectedTaskId } from '@igps/top-utility';
import { DocumentBasicDetails, RemoveDocumentMatDialogData } from "../../../models/matDialogDataModel";

@Component({
    selector: '',
    templateUrl: 'removeDocument-popup.html',
    styleUrls: ['removeDocument-popup.scss']
})
export class DialogOverviewExampleDialog {
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: RemoveDocumentMatDialogData, private documentService: DocumentService,
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
    removeDocument() {
        showSpinnerEvent.emit(true)
        const docRequest: DocumentBasicDetails = {
            documentExtensionType: this.data.documentDetails.documentExtensionType,
            documentName: this.data.documentDetails.documentName,
            documentType: this.data.documentDetails.documentType,
            taskId: this.data.taskInfo.id,
            externalSystemTaskId: this.data.taskInfo.externalSystemTaskId,
            internalGroupId : this.data.taskInfo.internalGroupId
        }
        this.documentService.removedocument(this.data.taskInfo.fastFileId, this.data.documentDetails.documentId, docRequest)
            .pipe(
                finalize(() => showSpinnerEvent.emit(false))
            )
            .subscribe(res => {

                if (res && res.statusCode === 200) {
                    selectedTaskId.emit(this.data.taskInfo.id);
                    ReloadDocumentListEvent.emit();
                    showToastEvent.emit({ message: "Delete Successfully", isError: false });
                    this.dialogRef.close();
                }
            }, error => {
                showToastEvent.emit({ message: error?.error.Detail, isError: true });
                showSpinnerEvent.emit(false)
            })
    }


}
