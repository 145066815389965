import { Component, OnInit, OnDestroy } from '@angular/core';
import { GetTaskBasicInfo, TaskBasicInfoEvent, ProcessDataEvent, GetProcessData} from '@igps/file-info-header';
import { Subscription } from 'rxjs';
import { ProcessDataModel } from '../../models';
import { TaskBasicInfoDataModel } from '../../models/taskBasicInfoDataModel';

@Component({
  selector: 'app-task-information',
  templateUrl: './task-information.component.html',
  styleUrls: ['./task-information.component.scss']
})
export class TaskInformationComponent implements OnInit,OnDestroy {

  linkToManual!: string;
  basicTaskInfo!: TaskBasicInfoDataModel;

  taskInformationSubscription$!: Subscription;
  processData!: ProcessDataModel;
  processDataSubscription$!:Subscription;

  // tslint:disable-next-line:no-empty
  constructor() { }

  ngOnDestroy(): void {
    this.taskInformationSubscription$?.unsubscribe();
    this.processDataSubscription$?.unsubscribe();
  }

  ngOnInit(): void {
    this.processDataSubscription$ = ProcessDataEvent.subscribe(data => {
      this.processData = data;
      if(this.processData){
        this.linkToManual = this.processData.linkToManual;
      }
    });
    if(!this.processData){
      this.processData = GetProcessData();
      if(this.processData){
        this.linkToManual = this.processData.linkToManual;
      }
    }
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe(data => {
      if(this.basicTaskInfo?.id !== data.taskinfo?.id){
        this.basicTaskInfo = data.taskinfo;
      }
    });
    if(!this.basicTaskInfo){
      this.basicTaskInfo = GetTaskBasicInfo();
    }
  }

}
