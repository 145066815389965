import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetTaskBasicInfo, TaskBasicInfoEvent } from '@igps/file-info-header';
import { finalize, Subscription } from 'rxjs';
import { showSpinnerEvent, showToastEvent } from '../../../../app/app.component';
import { DocumentExtensionEnum } from '../../../models/documentExtension';
import { DocumentRepository, OnChangeDocumentRepository, UpdateDocumentRepositoryRequest } from '../../../models/documentRepository';
import { Source, TaskBasicInfoDataModel } from '../../../models/taskBasicInfoDataModel';
import { DocumentService } from '../../../services/document.service';
import { ReloadDocumentListEvent } from '../doc-upload/doc-upload.component';
import { selectedTaskId } from '@igps/top-utility';
import { Sort } from '@angular/material/sort';
import { getTimezone, timezone$ } from '@igps/client-root-config';

@Component({
  selector: 'app-browse-doc-repo-popup',
  templateUrl: './browse-doc-repo-popup.component.html',
  styleUrls: ['./browse-doc-repo-popup.component.scss']
})
export class BrowseDocRepoPopupComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<BrowseDocRepoPopupComponent>,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zone: NgZone,
    // private commonService: CommonService
    ) { }

  documentRepository: DocumentRepository[] = [];
  onChangeDocumentRepository: OnChangeDocumentRepository[] = [];

  basicTaskInfo!: TaskBasicInfoDataModel;
  taskInformationSubscription$!: Subscription;
  supportedImage: string[] = [
    'TIF',
    'TIFF',
    'JPG',
    'JPEG'
  ];
  isSubmitDisabled: boolean = true;

  sortedData: any;

  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  selectedRows : number[] = [];

  ngOnDestroy(): void {
    this.timezoneSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
        this.timezone = timezone;
    });
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe((data) => {
      if (this.basicTaskInfo?.id !== data.taskinfo?.id) {
        this.basicTaskInfo = data.taskinfo;
        if (this.basicTaskInfo) {
          this.getDocuments();
        }
      }
    });
    if (!this.basicTaskInfo) {
      this.basicTaskInfo = GetTaskBasicInfo();
      if (this.basicTaskInfo) {
        this.getDocuments();
      }
    }
  }

  getDocuments() {
    this.zone.run(() => {
      showSpinnerEvent.emit(true);
      if(this.basicTaskInfo.sourceId === Source.Ignite){
      this.documentService.getDocumentRepository(
        this.basicTaskInfo.fastFileId,
        this.basicTaskInfo.externalSystemTaskId
      )
        .pipe(finalize(() => showSpinnerEvent.emit(false)))
        .subscribe({
          next: (result) => {
            this.documentRepository = result;
            this.sortedData = this.documentRepository.slice();
            const sortrequest: Sort = {
              active: 'documentName',
              direction: 'asc'
            }
            this.sortData(sortrequest);
          },
          error: (error: HttpErrorResponse) => {
            showToastEvent.emit({
              message: error?.error.Detail,
              isError: true,
            });
          }
        });
      }
      else{
        this.documentService.getDocumentRepositoryForInternalTask(
          this.basicTaskInfo.fastFileId,
          this.basicTaskInfo.internalGroupId
        )
          .pipe(finalize(() => showSpinnerEvent.emit(false)))
          .subscribe({
            next: (result) => {
              this.documentRepository = result;
              this.sortedData = this.documentRepository.slice();
              const sortrequest: Sort = {
                active: 'documentName',
                direction: 'asc'
              }
              this.sortData(sortrequest);
            },
            error: (error: HttpErrorResponse) => {
              showToastEvent.emit({
                message: error?.error.Detail,
                isError: true,
              });
            }
          });
      }
    });
  }

  getTime(date: Date): string {
    return (date.getHours() > 12 ?  (date.getHours() - 12 ) : (date.getHours() === 0 ? 12 : date.getHours())) + ":" + (date.getMinutes() >=10 ? date.getMinutes() : "0" + date.getMinutes()) + " " + (date.getHours() >= 12 ? "PM" : "AM");
  }

  onChange(event: any, item: DocumentRepository, documentSelectedIndex:number) {
    const index = this.onChangeDocumentRepository.findIndex(x => x.documentId === item.documentId);

    if(event.checked)
      {
        this.selectedRows.push(documentSelectedIndex);
      }
      else
      {
        this.selectedRows = this.selectedRows.filter(item => item !== documentSelectedIndex)
      }

    if (index !== -1) {
      this.onChangeDocumentRepository[index].newIsAssociated = event.checked;
    }
    else {
      this.onChangeDocumentRepository.push({
        documentId: item.documentId,
        documentName: item.documentName,
        newIsAssociated: event.checked,
        oldIsAssociated: item.isIpsLinked,
        isIpsLinked: false,
        isIgnite: item.isIgnite,
        documentTypeId: item.documentTypeId,
        documentExtensionType: item.documentExtensionType
      });
    }

    this.onChangeDocumentRepository = this.onChangeDocumentRepository.filter(x => x.newIsAssociated !== x.oldIsAssociated);
    this.isSubmitDisabled = this.onChangeDocumentRepository.length > 0 ? false : true;
  }

  onSubmit() {
    if(this.isSubmitDisabled) {
      showToastEvent.emit({
        message: "Manual user injection detected! Enabling submit button by force.",
        isError: true,
      });
      this.dialogRef.close();
      return;
    }
    const upadateRequest: UpdateDocumentRepositoryRequest[] = this.onChangeDocumentRepository.map(x => ({
      documentId: x.documentId,
      documentName: x.documentName,
      documentTypeId: x.documentTypeId,
      isIpsLinked: x.newIsAssociated,
      isIgnite: x.isIgnite,
      documentExtensionType: x.documentExtensionType
    }));

    showSpinnerEvent.emit(true);
    this.documentService.updateDocumentRepositories(this.basicTaskInfo.id, upadateRequest)
      .pipe(finalize(() => showSpinnerEvent.emit(false))).subscribe({
        next: () => {
          selectedTaskId.emit(this.basicTaskInfo.id);
          ReloadDocumentListEvent.emit();
          showToastEvent.emit({ message: "Updated Successfully", isError: false });
          this.dialogRef.close();
        },
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({
            message: error?.error.Detail,
            isError: true,
          });
        }
      });
  }

  previewImage(item: DocumentRepository) {
    showSpinnerEvent.emit(true);
    this.documentService.previewDocument(this.basicTaskInfo.fastFileId, item.documentId, item.documentExtensionType.split('.')[1], item.fileSize*1024*1024) // filesize will be in MB from eclipse
      .pipe(finalize(() => showSpinnerEvent.emit(false)))
      .subscribe({
        next: (result) => {
          // if (result && result.bytes) {
          if (result) {
            this.downloadOrPreview(result, item);
          } else {
            showSpinnerEvent.emit(false);
          }
        },
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({
            message: error?.error.Detail,
            isError: true,
          });
        }
      });
  }

  downloadOrPreview(result: Blob, item: DocumentRepository) {
    if (item.documentExtensionType.split('.')[1] === DocumentExtensionEnum.PDF) {
      // var blob = this.commonService.base64toBlob(result.bytes, "application/pdf");
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = URL.createObjectURL(result);
      showSpinnerEvent.emit(false);
      window.open(url, '_blank');
    }
    else {
      const fileNameExtension = `${item.documentName}.${item.documentExtensionType.split('.')[1].toLowerCase()}`
      // const mimetype = this.commonService.getMimeType(item.documentExtensionType.split('.')[1]);
      // var blob = this.commonService.base64toBlob(result.bytes, mimetype);
      const blobURL = URL.createObjectURL(result);
      const a = document.createElement("a");
      a.href = blobURL;
      a.download = String(fileNameExtension);
      a.click();
      showSpinnerEvent.emit(false);
      URL.revokeObjectURL(blobURL);
      a.remove();
    }
  }

  sortData(sort: Sort) {
    const data = this.documentRepository.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'documentName':
          return this.compare(a.documentName, b.documentName, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
