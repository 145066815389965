<article class="dialog__header mat-dialog-title">
  <h3 mat-dialog-title> Document Details </h3>
  <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>

<mat-dialog-content class="mat-typography mat-dialog-content">
  <div class="view-documents-text">
    <span>View document details below.</span>
  </div>
  <div class="main-content">
    <!-- LEFT COLUMN -->
    <div class="left-column">
      <div class="identify-document">
        <div class="heading">
          <span>1. Identify Document</span>
        </div>
        <div class="radio-group">
          <mat-radio-group class="ig2" required [(ngModel)]="selectedIgniteDocumentName" [disabled]="isMsg"
            (change)="onSelectDocument()">
            <ng-container *ngFor="let document of documentNames">
              <mat-radio-button [value]="document">
                {{document.documentName}}
              </mat-radio-button> <br>
            </ng-container>
          </mat-radio-group>
        </div>
      </div>
      <div class="identify-document-type-details">
        <div class="heading">
          <span>2. Identify Document Type and Details </span>
        </div>
        <div class="doc-inputs">
          <div class="row-one dropdowns">
            <model-filter-select class="ig2 first-fropdown" label="Document Type" [options]="imageTypes"
              nameField="description" [(selectedValue)]="selectedDocumentTypeId" [required]="true"
              (selectionChange)="documentTypeSelectionChange()"
              [disabled]="(!(selectedIgniteDocumentName && (selectedIgniteDocumentName?.documentName === 'Other Document Type'))) || isMsg">
            </model-filter-select>
            <model-filter-select *ngIf="!(selectedDocumentType?.id === 154 || selectedDocumentType?.id === 126)"
              class="ig2" label="Document Name" [options]="selectedDocumentType ? selectedDocumentType.imageNames : []"
              idField="description" nameField="description" [(selectedValue)]="selectedDocumentNameDescription"
              [required]="true" (selectionChange)="documentNameSelectionChange()"
              [disabled]="!(selectedIgniteDocumentName && (selectedIgniteDocumentName?.documentName === 'Other Document Type'))">
            </model-filter-select>
            <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill"
              *ngIf="selectedDocumentType?.id === 154 || selectedDocumentType?.id === 126">
              <mat-label class="mat-label-wrap">Document Name</mat-label>
              <input matInput [(ngModel)]="selectedDocumentNameDescription" [required]="true" [maxlength]="40">
            </mat-form-field>
          </div>
          <div class="row-two">
            <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
              <mat-label class="mat-label-wrap">Append (optional)</mat-label>
              <input class="mat-input-element-show-placeholder" matInput [(ngModel)]="append" [maxlength]="40" />
            </mat-form-field>
          </div>
          <div class="row-three">
            <model-filter-select class="ig2" [(searchText)]="actionsSearchText" label="Action (optional)"
              [options]="imageTriggers" nameField="description" [(selectedValue)]="selectedActionId" (openedChange)="onChangeAction()">
            </model-filter-select>
          </div>
          <div class="row-four">
            <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
              <mat-label class="mat-label-wrap">Notes (optional)</mat-label>
              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"
                placeholder="Enter notes here" [(ngModel)]="notes"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <!-- RIGHT COLUMN -->
    <div class="right-column">
      <div class="doc-div">
        <div class="doc-name-label">
          <span>Document Name</span>
        </div>
        <div class="doc-name">
          <div class="icon">
            <lib-icon
              [name]="isMsg ? 'mail' : 'file_'+ file.name.split('.')[file.name.split('.').length - 1].toLowerCase()">
            </lib-icon>
          </div>
          <div class="name">
            <p>{{append.length > 0 ? (selectedDocumentNameDescription ? selectedDocumentNameDescription : '') + ' ' +
              append.trim() : selectedDocumentNameDescription}}.{{file.name.split('.')[file.name.split('.').length -
              1].toLowerCase()}}</p>
          </div>
        </div>
      </div>
      <div class="doc-details" *ngIf="false">
        <div class="detail">
          <div class="caption">
            <span>Uploaded</span>
          </div>
          <div class="data">
            <span> </span>
            <!-- <span>Today at 1:28 PM by Supriya Soni</span> -->
          </div>
        </div>
        <div class="detail">
          <div class="caption">
            <span>Action</span>
          </div>
          <div class="data">
            <span> </span>
            <!-- <span>Some Action</span> -->
          </div>
        </div>
        <div class="detail">
          <div class="caption">
            <span>Size</span>
          </div>
          <div class="data">
            <span>{{formatBytes(file.size)}}</span>
          </div>
        </div>
        <div class="detail">
          <div class="caption">
            <span>Pages</span>
          </div>
          <div class="data">
            <span> </span>
            <!-- <span>6</span> -->
          </div>
        </div>
        <div class="detail">
          <div class="caption">
            <span>File Type</span>
          </div>
          <div class="data">
            <span> </span>
            <!-- <span>pdf</span> -->
          </div>
        </div>
        <div class="detail">
          <div class="caption">
            <span>Electronic File ID</span>
          </div>
          <div class="data">
            <span> </span>
            <!-- <span>2686946</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions">
  <button class="ig2 button button--no-border button--small" mat-button mat-dialog-close>Cancel</button>
  <button class="ig2 button button--primary button--small" mat-button
    [disabled]="!(selectedDocumentTypeId && selectedDocumentNameDescription)" (click)="uploadDocument()">
    Upload
  </button>
</mat-dialog-actions>