import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    constructor(private httpClient: HttpClient) { }
    readonly baseUrl = `${environment.igpsBase.apiUrl}`
    getEmailDetails(emailId: any) {
        return this.httpClient.get(`${this.baseUrl}` + `emailservice/dashboard/v1/emaildetail/` + `${emailId}`)
    }
}