<div class="portal">
  <div class="header">
    <div class="text"><span>{{ noteHeader }}</span></div>
    <div class="icon">
      <svg viewBox="0 0 24 24" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="close()">
        <path
          d="M3.646 3.646a.5.5 0 0 1 .708 0L12 11.293l7.646-7.647a.5.5 0 0 1 .638-.057l.07.057a.5.5 0 0 1 0 .708L12.707 12l7.647 7.646a.5.5 0 0 1 .057.638l-.057.07a.498.498 0 0 1-.708 0L12 12.707l-7.646 7.647a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1 0-.708L11.293 12 3.646 4.354a.5.5 0 0 1-.057-.638l.057-.07Z"
          fill="#043B7C"></path>
      </svg>
    </div>
  </div>

  <div class="content">
    <p class="text">Type your note below.</p>
    <div class="text-area-div">
      <mat-label class="label-color">Note</mat-label>
      <div id="notes" contenteditable="true" class="ig2" placeholder="Enter note here" #divValue (keydown)="maxLengthLimit($event)"
        (paste)="checkForMaxLengthPastedChars($event)" (input)="notesData = $any($event.target).innerText"></div>
      <p class="text-remaining">{{ notesData ? maxLengthAllowedChars - notesData.length : maxLengthAllowedChars }} character is remaining</p>
    </div>
  </div>

  <div class="footer">
    <div class="cancel">
      <span class="text" (click)="close()">Cancel</span>
    </div>
    <div class="save">
      <button id="btnSave" type="button" mat-flat-button (click)="saveNotes(false)"
        [disabled]="notesData.trim().length < 1 || notesData.trim().length > 3000"
        class="ig2 button button--primary button--small">
        Save
      </button>
    </div>
  </div>
</div>
