import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { showToastEvent } from '../../../app.component';
import { PhraseValueData } from '../../../models/phraseValueData';
import { DocumentService } from '../../../services/document.service';
import { PopoutService } from '../../../services/popout.service';
import { PhraseValuePopoutData, PopoutData, PopoutModalName, POPOUT_MODAL_DATA } from '../../../services/popout.tokens';
import { ReloadDocumentListEvent } from '../doc-upload/doc-upload.component';
import { selectedTaskId } from '@igps/top-utility';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-phrase-value-popup',
  templateUrl: './phrase-value-popup.component.html',
  styleUrls: ['./phrase-value-popup.component.scss']
})
export class PhraseValuePopupComponent implements OnInit,OnDestroy {

  constructor(@Inject(POPOUT_MODAL_DATA) public popoutData: PopoutData<PhraseValuePopoutData>, private popoutService: PopoutService, private documentService: DocumentService) { }

  subscriptionList: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s?.unsubscribe());
  }

  showSpinner: boolean = false;
  loaderGif: string = `${environment.UIOriginURL}/assets/logos/animation.gif`;
  isError!: boolean;
  errorMessage!: string;

  phraseValueData: PhraseValueData[] = [];
  phraseValueDataBackup: PhraseValueData[] = [];
  signatureCodes: string[] = ["BUSIGN", "SESIGN"];

  ngOnInit(): void {
    this.showSpinner = true;
    this.subscriptionList.push(this.documentService.getPhraseValueData(this.popoutData.basicTaskInfo.fastFileId, this.popoutData.data!.documentId)
    .pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe({
      next: (result) => {
        this.phraseValueData = result;
        this.phraseValueDataBackup = JSON.parse(JSON.stringify(result));
      },
      error: (error: HttpErrorResponse) => {
        this.close();
        showToastEvent.emit({ message: error?.error.Detail, isError: true });
      }
    }))
  }

  savePhraseValues(close: boolean){
    this.showSpinner = true;
    const changedPhraseValues: PhraseValueData[] = [];
    this.phraseValueData.forEach(phraseValue => {
      const oldPhraseValue = this.phraseValueDataBackup.find(pv => pv.id === phraseValue.id)!;
      phraseValue.elements.forEach(element => {
        const oldElement = oldPhraseValue.elements.find(e => e.id === element.id)!;
        if(element.value !== oldElement.value){
          if(!changedPhraseValues.some(p => p.id === phraseValue.id)){
            const p = { ...phraseValue, elements: [] };
            changedPhraseValues.push(p);
          }
          const phrase = changedPhraseValues.find(p => p.id === phraseValue.id)!;
          phrase.elements.push(element);
        }
      })
    });
    this.subscriptionList.push(this.documentService.savePhraseValueData(false, this.popoutData.basicTaskInfo.fastFileId, this.popoutData.data!.documentId, this.popoutData.data.documentName, this.popoutData.data.documentTemplateId, changedPhraseValues, this.popoutData.basicTaskInfo.id)
    .pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe({
      next: () => {
        // this.phraseValueData = result;
        selectedTaskId.emit(this.popoutData.basicTaskInfo.id);
        ReloadDocumentListEvent.emit();
        showToastEvent.emit({ message: "Document Phrases Saved Successfully.", isError: false });
        if(close){
          this.close();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.close();
        showToastEvent.emit({ message: error?.error.Detail, isError: true });
      }
    }))
  }

  saveAndImagePhraseValues(close: boolean){
    if(this.popoutData.data!.statusId === 188){
      this.showSpinner = true;
      // const imageDocument: ImageDocumentRequest = {
      //   documentId: this.popoutData.data!.documentId,
      //   draftFlag: false,
      //   publishFlag: false,
      //   additionalFormats: [],
      //   buyerSignaturesFlag: false,
      //   sellerSignaturesFlag: false,
      //   miscellanousTextSignaturesFlag: false,
      //   comments: null
      // };
      // this.subscriptionList.push(this.documentService.ImageOnlyPhraseValueData(this.popoutData.basicTaskInfo.fastFileId, imageDocument)
      this.subscriptionList.push(this.documentService.savePhraseValueData(true, this.popoutData.basicTaskInfo.fastFileId, this.popoutData.data!.documentId, this.popoutData.data.documentName, this.popoutData.data.documentTemplateId, [], this.popoutData.basicTaskInfo.id)
      .pipe(
        finalize(() => this.showSpinner = false)
      ).subscribe({
        next: () => {
          ReloadDocumentListEvent.emit();
          showToastEvent.emit({ message: "Image Saved Successfully.", isError: false });
          if(close){
            this.close();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.close();
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        }
      }))
    }
    else{
      this.showSpinner = true;
      const changedPhraseValues: PhraseValueData[] = [];
      this.phraseValueData.forEach(phraseValue => {
        const oldPhraseValue = this.phraseValueDataBackup.find(pv => pv.id === phraseValue.id)!;
        phraseValue.elements.forEach(element => {
          const oldElement = oldPhraseValue.elements.find(e => e.id === element.id)!;
          if(element.value !== oldElement.value){
            if(!changedPhraseValues.some(p => p.id === phraseValue.id)){
              changedPhraseValues.push({ ...phraseValue, elements: [] });
            }
            const phrase = changedPhraseValues.find(p => p.id === phraseValue.id)!;
            phrase.elements.push(element);
          }
        })
      });
      this.subscriptionList.push(this.documentService.savePhraseValueData(true, this.popoutData.basicTaskInfo.fastFileId, this.popoutData.data!.documentId, this.popoutData.data.documentName, this.popoutData.data.documentTemplateId, changedPhraseValues, this.popoutData.basicTaskInfo.id)
      .pipe(
        finalize(() => this.showSpinner = false)
      ).subscribe({
        next: () => {
          ReloadDocumentListEvent.emit();
          // this.phraseValueData = result;
          showToastEvent.emit({ message: "Document Phrases Saved and Imaged Successfully.", isError: false });
          if(close){
            this.close();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.close();
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        }
      }))
    }
  }

  close(){
    this.popoutService.closePopoutModal(PopoutModalName.phraseValues);
  }

  hasChanges(){
    return !(JSON.stringify(this.phraseValueData) === JSON.stringify(this.phraseValueDataBackup));
  }

}
