<div class="">
    <article class="dialog__header mat-dialog-title">
        <h3 mat-dialog-title> Document Details </h3>
        <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />

    </article>
    <mat-dialog-content class="mat-dialog-content mat-typography">

        <span>“Remove“ will remove the document from this Task.
        </span>
    </mat-dialog-content>
    <mat-dialog-actions class="mat-dialog-actions">
        <button mat-dialog-close
            class="mat-focus-indicator ig2 button button--no-border button--small mat-button mat-button-base"
            type="button"><span class="mat-button-wrapper">Cancel</span><span matripple=""
                class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button>
        <button class="ig2 button button--primary button--small" mat-button (click)="removeDocument()">Remove</button>
    </mat-dialog-actions>
    <!---->
    <!---->
</div>