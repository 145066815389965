import { refreshNoteList, EmailMessageId } from '@igps/top-utility';
import { PopoutService } from './../../../services/popout.service';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotesPopoutData, PopoutData, PopoutModalName, POPOUT_MODAL_DATA } from '../../../services/popout.tokens';
import { NotesService } from './../../../../services/notes-service';
import { Subscription, finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { notesDataSaveEvent } from '../warning-dialog/warning-dialog.component';
import { NavigateBackEventConfirm } from '@igps/file-info-header';
import { closeWarningEvent, showSpinnerEvent, showToastEvent } from '../../../app.component';
import { CreateEmailNote } from '../../../../models/create-note'

@Component({
  selector: 'app-portal-wrapper',
  templateUrl: './portal-wrapper.component.html',
  styleUrls: ['./portal-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  taskSource!: number;
  constructor(@Inject(POPOUT_MODAL_DATA) public popoutData: PopoutData<NotesPopoutData>, public dialog: MatDialog, private popoutService: PopoutService, public ref: ChangeDetectorRef, public noteService: NotesService) { }

  public subscribeObjectList: Subscription[] = [];
  showSpinner: boolean = false;
  isError!: boolean;
  errorMessage!: string;
  public notes!: number;
  public notesTo!: number;
  public maxLengthAllowedChars = 3000;
  public selectAllKeyValue = 65;
  public deleteKeyValue = 46;
  public backSpaceKeyValue = 8;
  public enterKeyValue = 13;
  public noteHeader = 'Add Note';
  public disbaleSaveBtn = false;
  @ViewChild('divValue') private divValue: any;
  public notesData = '';

  ngOnInit(): void {
    // tslint:disable-next-line:no-unused-expression
    this.divValue ? (this.divValue.nativeElement.innerText = this.notesData) : '';
    this.noteService.getprocessdetails(sessionStorage.getItem('ActiveProcessId')).subscribe((res: any) => {
      if (res) {
        this.taskSource = res.taskSource;
      }
    this.subscribeObjectList.push(notesDataSaveEvent.subscribe((navigateBack) => {
      if(!(this.notesData.trim().length < 1 || this.notesData.trim().length > 3000)){
        this.saveNotes(navigateBack);
      }
      else{
        showToastEvent.emit({ message: "Invalid Note", isError: true });
        closeWarningEvent.emit();
      }
    }));
    })
  }
  ngAfterViewInit(): void {
    if (this.popoutData.data) {
      // tslint:disable-next-line:no-unused-expression
      this.divValue ? (this.divValue.nativeElement.innerText = this.popoutData.data.notes) : '';// check
      this.notesData = this.divValue.nativeElement.innerText;
      this.noteHeader = 'Add Note';
      this.ref.detectChanges();
    }
  }
  saveNotes(navigateBack: boolean): void {
    if (this.taskSource == 1) {
    const body: { Notes: string } = {
      Notes: this.notesData.trim().replace(/\n\n/g, '\n')
    };
    showSpinnerEvent.emit(true);
    this.noteService.saveNotes(this.popoutData.basicTaskInfo.id, body).pipe(
      finalize(() => {
        showSpinnerEvent.emit(false);
      })
    ).subscribe({
      next: () => {
        closeWarningEvent.emit();
        this.divValue.nativeElement.innerText = "";
        showToastEvent.emit({ message: "Note Added Successfully.", isError: false });
        refreshNoteList.emit(true);
        if(navigateBack){
          NavigateBackEventConfirm.emit();
        }
        this.popoutService.closePopoutModal(PopoutModalName.taskNotes);
      },
      error: (error: HttpErrorResponse) => {
        showToastEvent.emit({ message: error?.error.Detail, isError: true });
      }
    });
    }
    else if (this.taskSource === 2) {
      showSpinnerEvent.emit(true);
      const createEmailNote: CreateEmailNote = {
        emailId: String(sessionStorage.getItem('selectedEmailNoteTaskId')),
        notes: this.notesData.replace(/\n\n/g, '\n')
      };
      this.noteService.saveEmailNotes(createEmailNote.emailId, createEmailNote).pipe(
        finalize(() => {
          showSpinnerEvent.emit(false);
        })
      ).subscribe({
        next: () => {
          closeWarningEvent.emit();
          this.divValue.nativeElement.innerText = "";
          showToastEvent.emit({ message: "Note Added Successfully.", isError: false });
          EmailMessageId.emit(createEmailNote.emailId); 
          if (navigateBack) {
            NavigateBackEventConfirm.emit();
          }
          this.popoutService.closePopoutModal(PopoutModalName.taskNotes);
        },
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        }
      });
    }
  }
  preventInvalidChar(event: any): any {
    if (event.key === '`' || event.key === '~' || event.key === '{' || event.key === '}' || event.key === '|') {
      return event.preventDefault();
    }
  }
  maxLengthLimit(event: any): any {
    if (event.ctrlKey && event.which === this.selectAllKeyValue) {
      return true;
    }
    if (
      event.which !== this.backSpaceKeyValue &&
      event.which !== this.deleteKeyValue &&
      this.notesData &&
      this.notesData.length >= this.maxLengthAllowedChars
    ) {
      return event.preventDefault();
    } else if (event.which === this.enterKeyValue && this.notesData && this.notesData.length >= this.maxLengthAllowedChars - 1) {
      return event.preventDefault();
    }
  }
  checkForInvalidPastedChars(event: ClipboardEvent): any {
    const invalidCharList = ['{', '}', '`', '~', '|'];
    const ClipboardData = event.clipboardData;
    const str = ClipboardData?.getData('text');
    if (invalidCharList.some((c) => str?.includes(c))) {
      return event.preventDefault();
    }
  }
  /*
  *  It will check max length of copy paste data
  */
  checkForMaxLengthPastedChars(event: ClipboardEvent): any {
    const ClipboardData = event.clipboardData;
    const str = ClipboardData?.getData('text');
    if (str && this.notesData && this.notesData.length + str.length >= this.maxLengthAllowedChars) {
      const remainingAllowedLength = this.maxLengthAllowedChars - this.notesData.length;
      if (str.length > remainingAllowedLength) {
        // tslint:disable-next-line:no-unused-expression
        this.divValue ? (this.divValue.nativeElement.innerText = this.notesData + str.substring(0, remainingAllowedLength)) : '';
        this.notesData = this.divValue.nativeElement.innerText;
        return event.preventDefault();
      }
    } else if (str && str.length >= this.maxLengthAllowedChars) {
      // tslint:disable-next-line:no-unused-expression
      this.divValue ? (this.divValue.nativeElement.innerText = str.substring(0, this.maxLengthAllowedChars)) : '';
      this.notesData = this.divValue.nativeElement.innerText;
      return event.preventDefault();
    }
  }

  close(){
    this.popoutService.closePopoutModal(PopoutModalName.taskNotes);
  }

  public ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }

}
