import { UploadDocument, UploadLargeDocument } from './../../../models/uploadDocument';
import { DocumentName } from './../../../models/documentName';
import { catchError, concat, finalize, forkJoin, map, of } from 'rxjs';
import { DocumentService } from './../../../services/document.service';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Source, TaskBasicInfoDataModel } from '../../../models/taskBasicInfoDataModel';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageTrigger } from '../../../models/imageTrigger';
import { ImageType } from '../../../models/imageType';
import { ReloadDocumentListEvent } from '../doc-upload/doc-upload.component';
import { showSpinnerEvent, showToastEvent } from '../../../app.component';
import { selectedTaskId,EmailMessageId,EmailTaskId } from '@igps/top-utility';

@Component({
  selector: 'app-document-detail-popup',
  templateUrl: './document-detail-popup.component.html',
  styleUrls: ['./document-detail-popup.component.scss']
})
export class DocumentDetailPopupComponent implements OnInit {
  actionsSearchText:string = "";
  attachmentId!: string;

  constructor(public dialogRef: MatDialogRef<DocumentDetailPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private docRepoService: DocumentService, private cdref: ChangeDetectorRef) { }

  basicTaskInfo!: TaskBasicInfoDataModel;
  documentNames: DocumentName[] = [];
  selectedIgniteDocumentName!: DocumentName;
  imageTypes: ImageType[] = [];
  imageTriggers: ImageTrigger[] = [];
  selectedDocumentType!: ImageType | undefined;
  selectedDocumentTypeId!: number | undefined;
  selectedDocumentNameDescription!: string;
  selectedActionId!: number | undefined;
  append: string = "";
  notes: string = "";
  file!: File;
  isMsg: boolean = false;
  docAttachments!:string;

  ngOnInit(): void {
    this.basicTaskInfo = this.data.taskInfo;
    this.file = this.data.file;
    this.isMsg = this.file.name.split('.')[this.file.name.split('.').length - 1].toLowerCase() === 'msg';
    console.log(this.data.attachments)
    if (typeof this.data.attachments !== 'undefined' && this.data.attachments.length > 0)
      this.docAttachments = this.data.attachments.map((obj: { attachmentName: any; }) => obj.attachmentName).join(', ');
    else if (typeof this.data.attachments !== 'undefined' &&  this.data.attachments.id !== undefined)
      this.attachmentId = this.data.attachments.id;
    else {
      this.docAttachments = "";
      this.attachmentId = "";
    }

    showSpinnerEvent.emit(true);
    this.documentNames = [];
    let documentNamesApi = this.docRepoService.getDocumentNames(this.basicTaskInfo.externalSystemTaskId).pipe(
      catchError(() => {
        return of([]);
      })
    );
    let imageTypesApi = this.docRepoService.getImageTypes();
    if(this.isMsg){
      const imagetype: ImageType = {
        description: "Miscellaneous",
        id: 154,
        imageNames: [],
        objectCd : null
      };
      documentNamesApi = of([]);
      imageTypesApi = of([imagetype]);
    }
    const documentNamesObserable = documentNamesApi.pipe(
      map(result => {
        this.documentNames = result;
        this.documentNames = this.documentNames.filter(dn => dn.documentType?.length > 0 && dn.documentName?.length > 0);
      }),
      finalize(() => {
        this.documentNames.push({
          isAssociated: false,
          documentName: "Other Document Type",
          documentType: "",
          imageTriggerName: "",
          actionRuleSetUpId: 0
        });
        if (this.documentNames.length === 1) {
          this.selectedIgniteDocumentName = this.documentNames.find(d => d.documentName === "Other Document Type")!;
        }
        if(this.isMsg){
          this.selectedIgniteDocumentName = this.documentNames.find(d => d.documentName === "Other Document Type")!;
        }
      })
    );
    const imageTypesObservable = imageTypesApi.pipe(
      map(result => {
        this.imageTypes = result.filter(it => ((it.imageNames?.length > 0) || (it.id === 154) || (it.id === 126)));
        if(this.isMsg){
          this.selectedDocumentTypeId = 154;
          this.selectedDocumentType = this.imageTypes.find(i => i.id === 154)!;
          this.documentNameSelectionChange();
          const filename = this.file.name.slice(0, -4);
          this.selectedDocumentNameDescription = filename.slice(0, 40);
        }
      })
    );
    const imageTriggersApi = this.docRepoService.getImageTriggers().pipe(
      map(result => {
        result.unshift({
          id: -1,
          description: '',
          objectCd: ''
        });
        this.imageTriggers = result;
      })
    );
    forkJoin([
      documentNamesObserable,
      imageTypesObservable,
      imageTriggersApi
    ]).pipe(
      finalize(() => {
        showSpinnerEvent.emit(false);
        this.documentNames = this.documentNames.filter(dn => (this.imageTypes.some(it => it.id.toString() === dn.documentType) || dn.documentName === "Other Document Type"));
      })
    )
      .subscribe({
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        }
      });
  }

  onChangeAction(){
    this.cdref.detectChanges();
    if(this.selectedActionId === -1){
      this.selectedActionId = undefined;
    }
  }

  onSelectDocument(){
    this.selectedDocumentType = undefined;
    this.selectedDocumentTypeId = undefined;
    this.selectedDocumentNameDescription = "";
    this.selectedActionId = undefined;
    this.append = "";
    if(this.selectedIgniteDocumentName?.documentName !== "Other Document Type"){
      this.selectedDocumentType = this.imageTypes.find(i => i.id.toString() === this.selectedIgniteDocumentName.documentType)!;
      this.selectedDocumentTypeId = this.selectedDocumentType.id;
      const igniteDocumentName = this.selectedDocumentType.imageNames.find(dn => dn.description.toLowerCase() === this.selectedIgniteDocumentName.documentName.toLowerCase());
      if(igniteDocumentName){
        this.selectedDocumentNameDescription = igniteDocumentName.description;
        if(this.imageTriggers.some(it => it.id === +this.selectedIgniteDocumentName.imageTriggerName)){
          this.selectedActionId = +this.selectedIgniteDocumentName.imageTriggerName;
        }
      }
      else{
        showToastEvent.emit({ message: "Document Name does not exist in the document type.", isError: true });
      }
    }
  }

  documentTypeSelectionChange(){
    this.selectedDocumentType = this.imageTypes.find(i => i.id === this.selectedDocumentTypeId)!;
    this.selectedDocumentNameDescription = "";
    this.append = "";
  }

  documentNameSelectionChange() {
    this.append = "";
  }

  uploadDocument() {

    const docDetailLarge: UploadLargeDocument = {
      image: this.data.file,
      fileId: this.basicTaskInfo.fastFileId,
      taskId: this.basicTaskInfo.id,
      documentTypeId: this.selectedDocumentTypeId!,
      documentName: `${this.selectedDocumentNameDescription} ${this.append.trim()}`,
      emailMessageID : this.basicTaskInfo.emailId,
      isMerged : false,
      AttachmentNames : this.docAttachments,
      AttachmentId :this.attachmentId
    };
    const docDetail: UploadDocument = {
      image: this.data.file,
      fileId: this.basicTaskInfo.fastFileId,
      taskId: this.basicTaskInfo.id,
      documentTypeId: this.selectedDocumentTypeId!,
      documentName: `${this.selectedDocumentNameDescription} ${this.append.trim()}`
    };
    if (this.selectedActionId) {
      docDetail.imageTriggerId = this.selectedActionId;
      docDetail.imageTriggerDescription = this.imageTriggers.find(it => it.id === this.selectedActionId)?.description;
    }
    if (this.notes.trim()) {
      docDetail.comments = this.notes.trim();
    }
    showSpinnerEvent.emit(true);
    if (this.file.name === 'MergedDocument.pdf' && this.basicTaskInfo.sourceId === Source.IgnitePS) {
      docDetailLarge.isMerged = true;
      docDetailLarge.emailMessageID = this.basicTaskInfo.emailId;
      docDetailLarge.AttachmentNames = this.docAttachments
    }
    const smallFileUpload = this.docRepoService.uploadDocument(docDetail);
    const largeFileUpload = this.docRepoService.uploadLargeDocument(docDetailLarge);
    var apiToCall = ((docDetail.image.size > (5 * 1024 * 1024))  || (docDetailLarge.image.size > (5 * 1024 * 1024)))? largeFileUpload : smallFileUpload;  // largeFileUpload for files greater than 10MB

    if (this.file.name === 'MergedDocument.pdf' || this.basicTaskInfo.emailId) {
      apiToCall = largeFileUpload;
    }

    apiToCall.pipe(
        finalize(() => showSpinnerEvent.emit(false))
      )
      .subscribe({
        next: () => {
          selectedTaskId.emit(this.data.taskInfo.id);
          EmailMessageId.emit(this.data.taskInfo.emailId);
          EmailTaskId.emit(this.data.taskInfo.id);
          ReloadDocumentListEvent.emit();
          showToastEvent.emit({ message: "Uploaded Successfully", isError: false });
          this.dialogRef.close("success");
        },
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        }
      });
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  clone(object: any){
    return JSON.parse(JSON.stringify(object));
  }

}
