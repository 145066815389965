import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, finalize, forkJoin, map, of } from 'rxjs';
import { showSpinnerEvent, showToastEvent } from '../../../app.component';
import { DocumentName } from '../../../models/documentName';
import { EclipseImageResponse } from '../../../models/eclipseImageResponse';
import { ImageTrigger } from '../../../models/imageTrigger';
import { ImageType } from '../../../models/imageType';
import { DocumentService } from '../../../services/document.service';
import { getTimezone, timezone$ } from '@igps/client-root-config';
import { Source } from '../../../models/taskBasicInfoDataModel';
@Component({
    selector: 'app-image-detail-popup',
    templateUrl: './image-detail-popup.component.html',
    styleUrls: ['./image-detail-popup.component.scss']
})
export class ImageDetailPopupComponent implements OnInit, OnDestroy {
    documentID: number = 0;
    fileID: number = 0;
    documentDetailsDto!: EclipseImageResponse;
    imageTriggers: ImageTrigger[] = [];
    selectedActionId!: number | undefined;
    documentNames: DocumentName[] = [];
    selectedIgniteDocumentName!: DocumentName;
    isMsg: any;
    selectedDocumentNameDescription!: string;
    imageTypes: ImageType[] = [];
    selectedDocumentTypeId!: number | undefined;
    file: any;
    selectedDocumentType!: ImageType | undefined;
    append: string = '';
    notes: any;
    timezoneSubscription!: Subscription;
    timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
    constructor(public dialogRef: MatDialogRef<ImageDetailPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private documentService: DocumentService) {

    }
    ngOnDestroy(): void {
        this.timezoneSubscription?.unsubscribe();
    }
    ngOnInit(): void {
        this.timezone = getTimezone();
        this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
            this.timezone = timezone;
        });
        showSpinnerEvent.emit(true);
        this.documentID = this.data.document.documentId;
        this.fileID = this.data.taskInfo.fastFileId;
        this.file = this.data.document.documentName;
        this.isMsg = this.file.split('.')[this.file.split('.').length - 1].toLowerCase() === 'msg';
        this.documentNames = [];
        let documentNamesApi: Observable<DocumentName[]> = of([]);
        if (this.data.taskInfo.source === Source.Ignite) {
            documentNamesApi = this.documentService.getDocumentNames(this.data.taskInfo.externalSystemTaskId);
        }
        let imageTypesApi = this.documentService.getImageTypes();
        if (this.isMsg) {
            const imagetype: ImageType = {
                description: "Miscellaneous",
                id: 154,
                imageNames: [],
                objectCd: null
            };
            documentNamesApi = of([]);
            imageTypesApi = of([imagetype]);
        }
        const documentNamesObserable = documentNamesApi.pipe(
            map((result: any) => {
                this.documentNames = result;
                this.documentNames = this.documentNames.filter((dn: { documentType: string | any[]; documentName: string | any[]; }) => dn.documentType?.length > 0 && dn.documentName?.length > 0);
            }),
            finalize(() => {
                this.documentNames.push({
                    isAssociated: false,
                    documentName: "Other Document Type",
                    documentType: "",
                    imageTriggerName: "",
                    actionRuleSetUpId: 0
                });
                if (this.documentNames.length === 1 || this.isMsg) {
                    this.selectedIgniteDocumentName = this.documentNames.find((d: { documentName: string; }) => d.documentName === "Other Document Type")!;
                }
            })
        );
        const imageTypesObservable = imageTypesApi.pipe(
            map((result: any[]) => {
                this.imageTypes = result.filter(it => ((it.imageNames?.length > 0) || (it.id === 154) || (it.id === 126)));
                if (this.isMsg) {
                    this.selectedDocumentTypeId = 154;
                    this.selectedDocumentType = this.imageTypes.find((i) => i.id === 154)!;
                    this.documentNameSelectionChange();
                    const filename = this.file.slice(0, -4);
                    this.selectedDocumentNameDescription = filename.slice(0, 40);
                }
            })
        );
        const imageTriggersApi = this.documentService.getImageTriggers().pipe(
            map(result => {
                this.imageTriggers = result;
            })
        );
        forkJoin([
            documentNamesObserable,
            imageTypesObservable,
            imageTriggersApi
        ]).pipe(
            finalize(() => {
                showSpinnerEvent.emit(false);
                this.documentNames = this.documentNames.filter((dn: { documentType: any; documentName: string; }) => (this.imageTypes.some((it: { id: { toString: () => any; }; }) => it.id.toString() === dn.documentType) || dn.documentName === "Other Document Type"));
            })
        )
            .subscribe({
                next: () => {
                    this.getDocumentImageDetails();
                },
                error: (error: HttpErrorResponse) => {
                    showToastEvent.emit({ message: error?.error.Detail, isError: true });
                }
            });

    }
    documentNameSelectionChange() {
        this.append = "";
    }
    onSelectDocument() {
        this.selectedDocumentType = undefined;
        this.selectedDocumentTypeId = undefined;
        this.selectedDocumentNameDescription = "";
        this.append = "";
        this.selectedDocumentType = this.imageTypes.find((i) => i.id === +this.documentDetailsDto.type.id)!;
        this.selectedDocumentTypeId = this.selectedDocumentType?.id;
        const igniteDocumentName = this.selectedDocumentType?.imageNames.find((x) => this.documentDetailsDto.name.match(x.description));
        if (igniteDocumentName) {
            this.selectedDocumentNameDescription = igniteDocumentName.description;
            this.append = this.documentDetailsDto.name.trim().replace(this.selectedDocumentNameDescription, '');
        }
        else {
            this.selectedDocumentNameDescription = this.documentDetailsDto.name.trim();
        }
        showSpinnerEvent.emit(false);
    }
    documentTypeSelectionChange() {
        this.selectedDocumentType = this.imageTypes.find((i: { id: any; }) => i.id === this.selectedDocumentTypeId)!;
        this.selectedDocumentNameDescription = "";
        this.append = "";
    }
    getDocumentImageDetails() {
        showSpinnerEvent.emit(true);
        this.documentService.getDocumentImageDetails(this.fileID, this.documentID).pipe(
            finalize(() => showSpinnerEvent.emit(false))
        ).subscribe({
            next: (result) => {
                this.documentDetailsDto = result;
                this.notes = this.documentDetailsDto.comments === undefined ? '' : this.documentDetailsDto.comments;
                const documentName = this.documentNames.find((d) => this.documentDetailsDto.name.trim().includes(d.documentName) && d.documentType === this.documentDetailsDto.type.id.toString());
                this.selectedIgniteDocumentName = documentName || this.documentNames.find((d) => d.documentName === "Other Document Type")!;
                this.selectedActionId = result.trigger?.id;
                this.selectedDocumentNameDescription = this.documentDetailsDto.name.trim();
                this.onSelectDocument();
            },
            error: (error: HttpErrorResponse) => {
                showToastEvent.emit({
                    message: error?.error.Detail,
                    isError: true,
                });
                showSpinnerEvent.emit(false);
            }
        });
    }
    closeDialog(): void {
        this.dialogRef.close();
    }
    formatBytes(bytes: number): string {
        const converted = bytes / (1024 * 1024);
        const convertedStr = converted.toFixed(2);
        return convertedStr === '0.00' && bytes > 0 ? '0.01' : convertedStr;
    }

}
