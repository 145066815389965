import { ImageTrigger } from './../models/imageTrigger';
import { DocumentName } from './../models/documentName';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, map, mergeMap, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ImageType } from '../models/imageType';
import { UploadDocument, UploadLargeDocument } from '../models/uploadDocument';
import { Template } from '../models/template';
import { PhraseValueData } from '../models/phraseValueData';
import { LookUpModel } from '../models/lookUpModel';
import { EclipseDocumentsResponse } from '../models/eclipseDocumentResponse';
import { DocumentRepository, UpdateDocumentRepositoryRequest } from '../models/documentRepository';
import { DocumentDetails } from '../models/documentDetails';
import { ImageDocumentDeliveryResponse, ImageDocumentRequest } from '../models/imageDelivery';
import { DocumentExtensionEnum } from '../models/documentExtension';
import { EclipseImageResponse } from '../models/eclipseImageResponse';
import { TemplateTypeModel } from '../models/templateTypeModel';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {


  readonly docRepoBaseUrl = `${environment.docRepoBase.apiUrl}`;
  readonly domainDataSearchBaseUrl = `${environment.domainDataSearchBase.apiUrl}`;
  readonly domainDataUrl = `${environment.igpsBase.apiUrl}`

  constructor(private httpClient: HttpClient) { }

  getDocumentNames(externalSystemTaskId: number): Observable<DocumentName[]> {
    if (externalSystemTaskId === null || externalSystemTaskId === undefined) {
      return of([]);
    }
    return this.httpClient.get<DocumentName[]>(`${this.docRepoBaseUrl}/docrepo/v1/documentnames/filetaskid/${externalSystemTaskId}`);
  }

  getTemplateRegions(): Observable<LookUpModel[]> {
    return this.httpClient.get<LookUpModel[]>(`${this.domainDataUrl}` + `eclipse/fast/lookups/regions`);
  }

  getImageTypes(): Observable<ImageType[]> {
    return this.httpClient.get<ImageType[]>(`${this.docRepoBaseUrl}/docrepo/v1/lookup/imagetypes`);
  }

  getImageTriggers(): Observable<ImageTrigger[]> {
    return this.httpClient.get<ImageTrigger[]>(`${this.docRepoBaseUrl}/docrepo/v1/lookup/imagetriggers`);
  }
  
  getDocmentsList(fastFileNumber: number, externalSystemTaskId: number): Observable<Template[]> {
    return this.httpClient.get<any>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileNumber}/${externalSystemTaskId}`);
  }
  getDocmentsListInternalTask(fastFileNumber: number, internalGroupId: string): Observable<Template[]> {
    return this.httpClient.get<any>(`${this.docRepoBaseUrl}/docrepo/internaltask/v1/documents/${fastFileNumber}/${internalGroupId}`);
  }
  getDocumentPackage(fastFileId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileId}/documentpackages`);
  }
  associatepackagetofile(fastFileId: number, id: any, requestbody: any): Observable<any> {
    return this.httpClient.post<any>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileId}/documentpackages/${id}/associateddocuments`, requestbody);
  }

  getTemplateTypes(): Observable<TemplateTypeModel[]> {
    return this.httpClient.get<TemplateTypeModel[]>(`${this.domainDataUrl}` + `eclipse/fast/lookups/documenttypes`);
  }

  getAllDocumentTemplateByRegionIdTypeId(regionId: number, typeId: number, searchValue: string = '*'): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.domainDataUrl}` + `domaindata/listingTemplate/v1/doctemplates/${regionId}/${typeId}/${searchValue}`);
  }

  getTemplates(processId?: string): Observable<Template[]> {
    const body = {
      PageRequest: {
        PageNumber: 1,
        PageSize: 500
      },
      SortRequest: {
        Field: "ProcessName.keyword",
        Direction: 1
      },
      Filters: [
        {
          Field: "ProcessId.keyword",
          Value: processId,
          Operator: 3,
          DataType: 2
        },
        {
          Field: "Status.Id",
          Value: "1",
          Operator: 1,
          DataType: 1
        },
      ]
    };

    return this.httpClient.post<{
      totalItemCount: number;
      totalPageCount: number;
      pageNumber: number;
      count: number;
      pageSize: number;
      items: Template[];
    }>(`${this.domainDataSearchBaseUrl}/listingTemplate/v1/search`, body).pipe(
      map(d => {
        return d.items;
      })
    )
  }

  addTemplateDocument(taskId: string, fileId: number, templateRegionId: number, templateName: string, documentTypeId: number, documentTemplateId: number): Observable<EclipseDocumentsResponse> {
    const body = {
      fileId,
      templateRegionId,
      templateName,
      documentTypeId,
      documentTemplateId
    };
    return this.httpClient.post<EclipseDocumentsResponse>(`${this.docRepoBaseUrl}/docrepo/v1/documents/addtemplatedocument/${taskId}`, body);
  }

  uploadDocument(uploadDocument: UploadDocument): Observable<number> {
    const formData: any = new FormData();
    formData.append('Image', uploadDocument.image);
    formData.append('TaskId', uploadDocument.taskId);
    formData.append('DocumentTypeId', uploadDocument.documentTypeId);
    formData.append('DocumentName', uploadDocument.documentName);
    if (uploadDocument.imageTriggerId) {
      formData.append('ImageTriggerId', uploadDocument.imageTriggerId);
    }
    if (uploadDocument.imageTriggerDescription) {
      formData.append('ImageTriggerDescription', uploadDocument.imageTriggerDescription);
    }
    if (uploadDocument.comments) {
      formData.append('Comments', uploadDocument.comments);
    }
    return this.httpClient.post<number>(`${this.docRepoBaseUrl}/docrepo/v1/documents/upload/${uploadDocument.fileId}`, formData);
  }

  uploadLargeDocument(uploadDocument: UploadLargeDocument): Observable<number> {
    const body = {
      documentTypeId: uploadDocument.documentTypeId,
      documentName: uploadDocument.documentName,
      fileName: uploadDocument.image.name,
      taskId: uploadDocument.taskId,
      comments: uploadDocument.comments,
      imageTriggerDescription: uploadDocument.imageTriggerDescription,
      imageTriggerId: uploadDocument.imageTriggerId,
      isMerged :uploadDocument.isMerged,
      emailMessageID:uploadDocument.emailMessageID ,
      AttachmentNames : uploadDocument.AttachmentNames,
      AttachmentId :uploadDocument.AttachmentId === ""? null : uploadDocument.AttachmentId
    };
    return this.getUploadPreSignedURL(uploadDocument.taskId, uploadDocument.image.name).pipe(
      mergeMap(url => {
        return this.uploadDocumenttoS3(url, uploadDocument.image).pipe(
          mergeMap(() => {
            return this.httpClient.post<number>(`${this.docRepoBaseUrl}/docrepo/v1/documents/upload/largedocuments/${uploadDocument.fileId}`, body);
          })
        );
      })
    );
  }

  private getUploadPreSignedURL(taskId: string, fileName: string): Observable<string> {
    return this.httpClient.post(`${this.docRepoBaseUrl}/docrepo/v1/GetSignedUrl/${taskId}`, { "fileName": fileName }, { responseType: 'text' });
  }

  private uploadDocumenttoS3(url: string, file: File) {
    return this.httpClient.put(url, file, { observe: 'response' });
  }

  previewDocument(fastFileId: any, imageId: any, extensionType: string, fileSize: number) {
    if (fileSize > (10 * 1024 * 1024)) {
      return this.previewLargeDocument(fastFileId, imageId, extensionType);
    }
    else {
      return this.httpClient.get<any>(`${this.docRepoBaseUrl}/docrepo/v1/documents/previewDocument/${fastFileId}/${imageId}`).pipe(
        map((result) => {
          return this.base64toBlob(result.bytes, extensionType === DocumentExtensionEnum.PDF ? "application/pdf" : this.getmimeType(extensionType));
        })
      )
    }
  }

  base64toBlob(base64Data: any, contentType: any) {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = window.atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  getmimeType(documentExtensionType: any) {
    switch (documentExtensionType) {
      case 'DOC':
        return 'application/msword';
        break;
      case 'DOCX':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'XLS':
        return 'application/vnd.ms-excel';
        break;
      case 'XLSX':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break
      case 'TIF':
      case 'TIFF':
        return 'image/tiff';
        break
      case 'JPEG':
      case 'JPG':
        return 'image/jpeg';
        break;
      case 'MSG':
        return 'application/vnd.ms-outlook';
        break;
      default:
        return 'application/octet-stream'
        break;
    }
  }

  previewLargeDocument(fastFileId: any, imageId: any, extensionType: string) {
    return this.httpClient.get(`${this.docRepoBaseUrl}/docrepo/v1/documents/previewDocumentUrl/${fastFileId}/${imageId}`, { responseType: 'text' }).pipe(
      mergeMap((url) => {
        return this.httpClient.get(url, { responseType: 'arraybuffer' }).pipe(
          map((response) => {
            return new Blob([response], { type: extensionType === DocumentExtensionEnum.PDF ? "application/pdf" : this.getmimeType(extensionType) });
          })
        );
      })
    )
  }

  getPhraseValueData(fastFileId: number, documentId: number): Observable<PhraseValueData[]> {
    return this.httpClient.get<PhraseValueData[]>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileId}/documentPhrases/${documentId}`);
  }

  removedocument(fastFileId: any, documentId: any, documentDetails: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: documentDetails
    };
    return this.httpClient.delete<any>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileId}/removedocument/${documentId}`, options)
  }

  savePhraseValueData(isImageDoc: boolean, fastFileId: number, documentId: number, documentName: string, documentTemplateId: number, phraseValueData: PhraseValueData[], taskId: string) {
    return this.httpClient.put<number>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileId}/documentPhrases/${taskId}`, { "documentPhrases": phraseValueData, "documentId": documentId, "documentName": documentName, "isImageDoc": isImageDoc }).pipe(
      mergeMap(response => {
        if (response > 0) {
          return this.imageDocumentDeliveryStatus(taskId, documentId, documentName, documentTemplateId, response);
        }
        else {
          return of([]);
        }
      })
    );
  }
  ImageOnlyPhraseValueData(fastFileId: number, imageDocument: ImageDocumentRequest): Observable<ImageDocumentDeliveryResponse> {
    return this.httpClient.post<ImageDocumentDeliveryResponse>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fastFileId}/imagedocumentdeliveries`, imageDocument);
  }
  imageDocumentDeliveryStatus(taskId: string, documentId: number, documentName: string, documentTemplateId: number, imageDocDeliveryId: number, count: number = 1): Observable<boolean> {
    return this.getImageDocumentDeliveryStatus(taskId, documentId, documentName, documentTemplateId, imageDocDeliveryId, count).pipe(
      mergeMap((response) => {
        if (!response) {
          return this.imageDocumentDeliveryStatus(taskId, documentId, documentName, documentTemplateId, imageDocDeliveryId, count + 1);
        }
        else {
          return of(response);
        }
      })
    );
  }

  private getImageDocumentDeliveryStatus(taskId: string, documentId: number, documentName: string, documentTemplateId: number, imageDocDeliveryId: number, count: number): Observable<boolean> {
    if (count < 5) {
      const body = { documentId, documentName, documentTemplateId };
      return of(1).pipe(delay(3000), mergeMap(() => this.httpClient.post<boolean>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${taskId}/imagedocumentdeliveries/${imageDocDeliveryId}`, body)));
    }
    else {
      return of(true);
    }
  }

  getDocumentRepository(fastFileNumber: number, externalSystemTaskId: number): Observable<DocumentRepository[]> {
    return this.httpClient.get<DocumentRepository[]>(`${this.docRepoBaseUrl}/docrepo/v1/alldocuments/${fastFileNumber}/${externalSystemTaskId}`);
  }

  getDocumentRepositoryForInternalTask(fastFileNumber: number, internalGroupId: string): Observable<DocumentRepository[]> {
    return this.httpClient.get<DocumentRepository[]>(`${this.docRepoBaseUrl}/docrepo/internaltask/v1/alldocuments/${fastFileNumber}/${internalGroupId}`);
  }

  updateDocumentRepositories(taskId: string, updateDocumentRepositoryRequest: UpdateDocumentRepositoryRequest[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.docRepoBaseUrl}/docrepo/v1/updateipsdocs/${taskId}`, updateDocumentRepositoryRequest);
  }
  getDocumentDetails(fileId: number, documentId: number): Observable<DocumentDetails> {
    return this.httpClient.get<DocumentDetails>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fileId}/documentdetails/${documentId}`);
  }
  getDocumentImageDetails(fileId: number, documentId: number): Observable<EclipseImageResponse> {
    return this.httpClient.get<EclipseImageResponse>(`${this.docRepoBaseUrl}/docrepo/v1/documents/${fileId}/images/${documentId}`);
  }
  mergeDocument(request: {}) {
    return this.httpClient.post(`${this.docRepoBaseUrl}/docrepo/v1/mergedocument`, request)
  }

}
