import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EmailAttachment } from "src/app/models/emailattachmentModel";
import { TaskBasicInfoDataModel } from "src/app/models/taskBasicInfoDataModel";
import { DocumentService } from "src/app/services/document.service";
import { EmailService } from "src/app/services/email.service";
import { DocumentDetailPopupComponent } from "../doc-repo/document-detail-popup/document-detail-popup.component";
import { showSpinnerEvent, showToastEvent } from "src/app/app.component";

@Component({
    selector: 'app-attach-email-popup',
    templateUrl: './attach-email-popup.component.html',
    styleUrls: ['./attach-email-popup.component.scss']
})
export class AttachEmailPopupComponent implements OnInit {
    attachments: EmailAttachment[] = [];
    validFileTypes: EmailAttachment[] = [];
    inValidFileTypes: EmailAttachment[] = [];
    selectAll: boolean = false;
    includeEmail: boolean = false;
    selectedValidAttachments: any = [];
    selectedInvalidAttachment: any = [];
    maxFileSize: number = 35 * 1024 * 1024; // 35 MB
    allowedExtensions: string[] = [
        'TIF',
        'TIFF',
        'PDF',
        'JPG',
        'JPEG',
        'DOC',
        'DOCX',
        'XLS',
        'XLSX',
        "MSG"
    ];
    constructor(@Inject(MAT_DIALOG_DATA) public data: { basicTaskInfo: TaskBasicInfoDataModel; },
        public ref: ChangeDetectorRef, private EmailService: EmailService, private docservice: DocumentService, private dialog: MatDialog, public dialogRef: MatDialogRef<AttachEmailPopupComponent>) {

    }

    ngOnInit(): void {
        showSpinnerEvent.emit(true);
        this.EmailService.getEmailDetails(this.data.basicTaskInfo.emailId).subscribe((res: any) => {
            if (res) {
                showSpinnerEvent.emit(false);
                let filterdata: EmailAttachment[] = [];
                this.attachments = res.attachments;
                this.attachments.map(x => {
                    x.checked = false;
                })

                this.attachments.filter((x => {
                    if (!/calendar/.exec(x.contentType)) {
                        filterdata.push(x);
                    }
                }))
                filterdata.filter((x => {
                    if (/pdf/.exec(x.contentType) || /tiff/.exec(x.contentType)) {
                        this.validFileTypes.push(x)
                    } else {
                        this.inValidFileTypes.push(x)
                    }
                }))

            }

        }, err => {
            showSpinnerEvent.emit(false);
        })
    }
    formatBytes(bytes: any, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    toggle(item: any, event: MatCheckboxChange) {
        if (event.checked) {
            this.selectedValidAttachments.push(item);
        } else {
            const index = this.selectedValidAttachments.indexOf(item);
            if (index >= 0) {
                this.selectedValidAttachments.splice(index, 1);
            }
            if (item == 'includeEmail' && event.checked == false) {
                this.includeEmail = false;
            }
        }

    }
    getSelectedAttachment(item: any, event: MatCheckboxChange) {
        if (event.checked) {
            this.selectedInvalidAttachment = []
            this.selectedInvalidAttachment.push(item);
        } else {
            const index = this.selectedInvalidAttachment.indexOf(item);
            if (index >= 0) {
                this.selectedInvalidAttachment.splice(index, 1);
            }
        }
    }
    exists(item: any) {

        return this.selectedValidAttachments.indexOf(item) > -1;
    };
    validexist(item: any) {
        return this.selectedInvalidAttachment.indexOf(item) > -1;

    }
    invalidexists(item: any) {
        if (this.selectedValidAttachments.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    validexists(item: any) {
        if (this.selectedInvalidAttachment.length > 0) {

            return true;
        }
        else {
            return false;
        }
    }

    isIndeterminate() {
        return (this.selectedValidAttachments.length > 0 && !this.isChecked());
    };

    isChecked() {
        return this.selectedValidAttachments.length === this.validFileTypes.length + 1;
    };



    toggleAll(event: MatCheckboxChange) {

        if (event.checked) {

            this.validFileTypes.forEach(row => {
                this.selectedValidAttachments.push(row)
            });
            this.selectedValidAttachments.push('includeEmail')
            this.includeEmail = true;
            let x = document.querySelectorAll('#includeEmail')
            x[0].className = 'mat-checkbox ig2 mat-accent mat-checkbox-checked';

        } else {
            this.selectedValidAttachments.length = 0;
            this.includeEmail = false;
            let x = document.querySelectorAll('#includeEmail')
            x[0].className = 'mat-checkbox ig2 mat-accent';
        }
    }
    public onInvalidFileSizeUpload(): boolean {
        // throw user a message that file size is grater than MaxFileSize of the application
        showToastEvent.emit({
            message:
                'The document exceeds the allowed limit. Please select a file that is less than 30 MB.',
            isError: true,
        });
        return false;
    }
    AttachDocument() {
        showSpinnerEvent.emit(true);
        let attachments: any = []
        if (this.selectedValidAttachments && this.selectedValidAttachments.length > 0) {
            this.selectedValidAttachments.forEach((x: any) => {
                if (x.id) {
                    let attachment = {
                        "attachmentId": x.id,
                        "attachmentName": x.name
                    }
                    attachments.push(attachment)
                }
                else if (x == 'includeEmail') {
                    this.includeEmail = true;
                }

            })
            let request = {
                "emailMessageId": this.data.basicTaskInfo.emailId,
                "includeMessageBody": this.includeEmail,
                "attachments": attachments

            }

            this.docservice.mergeDocument(request).subscribe((res: any) => {
                if (res) {
                    showSpinnerEvent.emit(false);
                    if (res?.size > (30 * 1024 * 1024)) {
                        showToastEvent.emit({ message: "<b>Document Merge Failed</b>. The document exceeds the allowed limit. Please select a file that is less than 30 MB.", isError: true });
                    }
                    else {
                        showToastEvent.emit({ message: " Merged Successfully", isError: false });

                        let filedata = new File([""], 'MergedDocument.pdf', { type: 'application/pdf', lastModified: 0 })
                        Object.defineProperty(filedata, 'size', {
                            value: res?.size
                        });
                        const dialogRef = this.dialog.open(DocumentDetailPopupComponent, {
                            panelClass: 'ig2',
                            width: '1040px',
                            disableClose: true,
                            data: {
                                taskInfo: this.data.basicTaskInfo,
                                file: filedata,
                                attachments: request.attachments
                            },
                        });
                        dialogRef.afterClosed().subscribe((result: string) => {

                            if (result == "success") {
                                this.dialogRef.close();
                            }
                        });
                    }
                }
            }, err => {
                showSpinnerEvent.emit(false);
                showToastEvent.emit({ message: "Merge unsuccessful. Contact your system administrator", isError: true });
            })
        }
        else {
            showSpinnerEvent.emit(false);
            const ext = this.selectedInvalidAttachment[0].name.split('.')[this.selectedInvalidAttachment[0].name.split('.').length - 1];
            if (this.allowedExtensions.lastIndexOf(ext.toUpperCase()) < 0) {
                this.onInvalidFileUpload()
                return;
            } else {
                let filedata = new File([""], this.selectedInvalidAttachment[0].name, { type: this.selectedInvalidAttachment[0].contentType, lastModified: 0 })
                Object.defineProperty(filedata, 'size', {
                    value: this.selectedInvalidAttachment[0].size
                });
                const dialogRef = this.dialog.open(DocumentDetailPopupComponent, {
                    panelClass: 'ig2',
                    width: '1040px',
                    disableClose: true,
                    data: {
                        taskInfo: this.data.basicTaskInfo,
                        file: filedata,
                        attachments: this.selectedInvalidAttachment[0]
                    },
                });
                dialogRef.afterClosed().subscribe((result: string) => {

                    if (result == "success") {
                        this.dialogRef.close();
                    }
                });
            }
        }
    }
    public onInvalidFileUpload(): boolean {
        // throw user a message that format is not applicable
        showToastEvent.emit({
            message:
                'File type is invalid. It must have an extension of .tif .tiff .pdf .jpg .jpeg .doc .docx .xls .xlsx .msg',
            isError: true,
        });
        return false;
    }
}