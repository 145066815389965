import { PortalWrapperComponent } from './../components/notes/portal-wrapper/portal-wrapper.component';
import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import {POPOUT_MODAL_DATA, POPOUT_MODALS, PopoutData, PopoutModalName, PopoutDataType} from './popout.tokens';
import { PhraseValuePopupComponent } from '../components/doc-repo/phrase-value-popup/phrase-value-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopoutService implements OnDestroy {
  styleSheetElement!: HTMLLinkElement;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef
  ) {
  }

  // tslint:disable-next-line: no-empty
  ngOnDestroy() {}

  openPopoutModal(data: PopoutData<PopoutDataType>) {
    const windowInstance = this.openOnce(
      // 'assets/modal/popout.html',
      '',
      data.modalName
    );

    // Wait for window instance to be created
    setTimeout(() => {
      this.createCDKPortal(data, windowInstance);
    }, 50);
  }

  openOnce(url: string, target: string) {
    // Open a blank "target" window
    // or get the reference to the existing "target" window
    // const winRef = window.open('', target, '', true);
    let width: number = 375;
    let height: number = 400;
    let top: number = 375;
    let left: number = 375;
    if(target === PopoutModalName.phraseValues){
      width = 550;
      height = 600;
      top = window.innerHeight/2;
      left = window.innerWidth/6;
    }
    const winRef: Window = window.open(url, target, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}` ) as Window;
    // If the "target" window was just opened, change its url
    // if (winRef.location.href === 'about:blank') {
    //   winRef.location.href = url;
    // }
    return winRef;
  }

  createCDKPortal(data: PopoutData<PopoutDataType>, windowInstance: Window) {
    if (windowInstance) {
      // Create a PortalOutlet with the body of the new window document
      const outlet: DomPortalOutlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
      // Copy styles from parent window
      this.styleSheetElement = this.getStyleSheetElement(windowInstance);

      setTimeout(() => {
        windowInstance.document.body.innerText = '';

        // Create an injector with modal data
        const injector = this.createInjector(data);

        // Attach the portal
        let componentInstance;
        if (data.modalName === PopoutModalName.taskNotes) {
          windowInstance.document.title = 'IgnitePS - Notes';
          componentInstance = this.attachNotesContainer(outlet, injector);
        }
        if (data.modalName === PopoutModalName.phraseValues) {
          windowInstance.document.title = 'IgnitePS - Document Phrases';
          componentInstance = this.attachPhraseValueContainer(outlet, injector);
        }

        if(!POPOUT_MODALS[data.modalName]){ POPOUT_MODALS[data.modalName] = {} }

        POPOUT_MODALS[data.modalName].windowInstance = windowInstance;
        POPOUT_MODALS[data.modalName].outlet = outlet;
        POPOUT_MODALS[data.modalName].componentInstance = componentInstance;
      }, 50);

      // this.styleSheetElement.onload = () => {
      //   // Clear popout modal content
      //   windowInstance.document.body.innerText = '';

      //   // Create an injector with modal data
      //   const injector = this.createInjector(data);

      //   // Attach the portal
      //   let componentInstance;
      //   if (data.modalName === PopoutModalName.taskNotes) {
      //     windowInstance.document.title = 'Notes';
      //     componentInstance = this.attachNotesContainer(outlet, injector);
      //   }

      //   POPOUT_MODALS.windowInstance = windowInstance;
      //   POPOUT_MODALS.outlet = outlet;
      //   POPOUT_MODALS.componentInstance = componentInstance;
      // };
    }
  }

  isPopoutWindowOpen(modalName: string) {
    if(!POPOUT_MODALS[modalName]){ return; }
    return POPOUT_MODALS[modalName].windowInstance && !POPOUT_MODALS[modalName].windowInstance.closed;
  }

  focusPopoutWindow(modalName: string) {
    if(!POPOUT_MODALS[modalName]){ return; }
    POPOUT_MODALS[modalName].windowInstance.focus();
  }

  closePopoutModal(modalName: string) {
    if(!POPOUT_MODALS[modalName]){ return; }
    if (POPOUT_MODALS[modalName].windowInstance) {
      POPOUT_MODALS[modalName].windowInstance.close();
    }
  }

  attachNotesContainer(outlet: DomPortalOutlet, injector: Injector) {
    const containerPortal = new ComponentPortal(PortalWrapperComponent, null, injector);
    const containerRef: ComponentRef<PortalWrapperComponent> = outlet.attach(containerPortal);
    return containerRef.instance;
  }

  attachPhraseValueContainer(outlet: DomPortalOutlet, injector: Injector){
    const containerPortal = new ComponentPortal(PhraseValuePopupComponent, null, injector);
    const containerRef: ComponentRef<PhraseValuePopupComponent> = outlet.attach(containerPortal);
    return containerRef.instance;
  }

  createInjector(data: PopoutData<PopoutDataType>): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(POPOUT_MODAL_DATA, data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  getStyleSheetElement(windowInstance: Window) {
    let index: number = 0;
    let styleSheet = document.createElement('link');
    document.querySelectorAll('link').forEach(htmlElement => {
      const styleSheetElement = document.createElement('link');
      if (htmlElement.rel === 'stylesheet') {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.href = absoluteUrl;
        windowInstance.document.head.appendChild(styleSheetElement);
      }
      if(index === 0){
        styleSheet = styleSheetElement;
      }
      index++;
    });
    document.querySelectorAll('style').forEach(htmlElement => {
      windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
    });
    windowInstance.document.body.style.margin = '0px';
    windowInstance.document.body.style.overflow = 'hidden';
    return styleSheet;
  }

}
