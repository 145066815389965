export class Events {
  public static TriggerEvents: string[] = [
    // "tasknotesaddedevent",
    // "tasknotesupdatedevent",
    "taskduedatechangedevent",
    "taskstartedevent",
    // "orderrequestedevent",
    "ordercancelledevent",
    "taskreturnedtoigpsevent",
    "taskcancelledevent",
    "taskassignedevent",
    "taskunassignedevent",
    "taskreturnedtobranchevent",
    "tasksenttoqueryevent",
    "taskputonholdevent",
    "taskremoveholdevent",
    "taskescalatedtoonshoreevent",
    "taskcompletedevent",
    "taskcompletedwithexceptionsevent",
    "tasksenttoprocessorevent",
    // "taskdocumentaddedevent",
    // "taskdocumentremovedevent",
    // "taskdocumentphrasevalueupdatedevent"

  ];
  public static NotesEvent: string[] = [
    "tasknotesaddedevent",
    "tasknotesupdatedevent"
  ];
  public static DocumentEvent: string[] = [
    "taskdocumentaddedevent",
    "taskdocumentremovedevent",
    // "taskdocumentphrasevalueupdatedevent"
    "taskdocumentassociatepackageevent"
  ];
}
