import { Subscription } from 'rxjs';
import { EventEmitter, ChangeDetectorRef, OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { PopoutService } from '../../../services/popout.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigateBackEventConfirm } from '@igps/file-info-header';
import { PopoutModalName } from '../../../services/popout.tokens';
import { closeWarningEvent, showToastEvent } from '../../../app.component';

export const notesDataSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit, OnDestroy {

  closeWarningEventSubscription!: Subscription;

  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      heading: string;
      message: string;
      modalName: string;
      data: any;
    },
    private cdref: ChangeDetectorRef,
    private popoutService: PopoutService
  ) { }

  ngOnInit(): void {
    this.closeWarningEventSubscription = closeWarningEvent.subscribe(() => {
      this.close();
    });
  }

  ngOnDestroy(): void {
    if(this.closeWarningEventSubscription){
      this.closeWarningEventSubscription.unsubscribe();
    }
  }

  close(): void {
    if(this.data.modalName === PopoutModalName.taskNotes){
      if(this.popoutService.isPopoutWindowOpen(this.data.modalName)){
        this.popoutService.focusPopoutWindow(PopoutModalName.taskNotes);
        this.popoutService.closePopoutModal(this.data.modalName);
      }
      NavigateBackEventConfirm.emit();
      this.dialogRef.close();
      this.cdref.detectChanges();
    }
    if(this.data.modalName === PopoutModalName.phraseValues){
      // do nothing, just close warning
      this.dialogRef.close();
      this.cdref.detectChanges();
      if(this.popoutService.isPopoutWindowOpen(this.data.modalName)){
        this.popoutService.focusPopoutWindow(PopoutModalName.phraseValues);
      }
      else{
        showToastEvent.emit({ message: "Phrase Value popout already closed.", isError: true });
      }
    }
  }

  SaveDiscard(){
    if(this.data.modalName === PopoutModalName.taskNotes){
      if(this.popoutService.isPopoutWindowOpen(this.data.modalName)){
        this.popoutService.focusPopoutWindow(PopoutModalName.taskNotes);
        notesDataSaveEvent.emit(true);
        this.cdref.detectChanges();
      }
      else{
        this.close();
        showToastEvent.emit({ message: "Notes popout closed before saving.", isError: true });
        this.cdref.detectChanges();
      }
    }
    if(this.data.modalName === PopoutModalName.phraseValues){
      if(this.popoutService.isPopoutWindowOpen(this.data.modalName)){
        this.popoutService.closePopoutModal(this.data.modalName);
        this.close();
        NavigateBackEventConfirm.emit();
        this.cdref.detectChanges();
      }
      else{
        this.close();
        showToastEvent.emit({ message: "Phrase Value popout closed before navigating.", isError: true });
        NavigateBackEventConfirm.emit();
        this.cdref.detectChanges();
      }
    }
  }

}
