import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, forkJoin, Subscription } from 'rxjs';
import { LookUpModel } from '../../../models/lookUpModel';
import { DocumentService } from '../../../services/document.service';
import { showSpinnerEvent, showToastEvent } from '../../../app.component';
import { showPhraseValuePopEvent } from '../doc-upload/doc-upload.component';
import { EclipseDocumentsResponse } from '../../../models/eclipseDocumentResponse';
import { TemplateTypeModel } from '../../../models/templateTypeModel';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit, OnDestroy {

  templatesInfoSubscription$!: Subscription
  addDocumentTemplateForm!: FormGroup;
  templateRegions!: LookUpModel[];
  templateTypes!: TemplateTypeModel[];
  templates!: LookUpModel[];
  taskInformationSubscription$!: Subscription;
  basicTaskInfo!: any;
  showWarning: boolean = false;

  constructor(private _documentService: DocumentService, private _formBuilder: FormBuilder, public _dialogRef: MatDialogRef<AddDocumentComponent>, @Inject(MAT_DIALOG_DATA) public _data: any) { }
  ngOnInit(): void {
    this.basicTaskInfo = this._data;
    showSpinnerEvent.emit(true);
    this.initialiseForm();
    const templateRegions = this._documentService.getTemplateRegions();
    const templateTypes = this._documentService.getTemplateTypes();
    this.templatesInfoSubscription$ = forkJoin([templateRegions, templateTypes])
      .pipe(
        finalize(() => showSpinnerEvent.emit(false))
      )
      .subscribe({
        next: (result: any) => {
          this.templateRegions = result[0] as LookUpModel[];
          this.templateTypes = result[1] as TemplateTypeModel[];
        },
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        }
      });
  }


  getNumberOfInvalidControls() {
    return Object.values(this.addDocumentTemplateForm.controls)
      .filter((c:any) => c.invalid).length <= 1;
  }
  onSearchFastFastDocument() {
    this.showWarning = false;
    if (Object.values(this.addDocumentTemplateForm.controls)
      .filter((c:any) => c.invalid).length <= 1)
      showSpinnerEvent.emit(true);
      this._documentService.getAllDocumentTemplateByRegionIdTypeId(this.addDocumentTemplateForm.value.fcTemplateRegion, this.addDocumentTemplateForm.value.fcTemplateType, this.addDocumentTemplateForm.value.fcTemplateDescription).pipe(
        finalize(() => showSpinnerEvent.emit(false))
      ).subscribe((result: LookUpModel[]) => {
        this.templates = result;
        this.showWarning = result?.length > 0 ? false : true;
      })
  }
  onSubmit() {
    showSpinnerEvent.emit(true);
    const selectedTemplate = this.templates.find(t => t.id === this.addDocumentTemplateForm.value.fcDocumentTemplateId);
    this._documentService.addTemplateDocument(this.basicTaskInfo.taskInfo.id, this.basicTaskInfo.taskInfo.fastFileId, this.addDocumentTemplateForm.value.fcTemplateRegion, selectedTemplate!.name, this.addDocumentTemplateForm.value.fcTemplateType, this.addDocumentTemplateForm.value.fcDocumentTemplateId).pipe(
      finalize(() => {
        showSpinnerEvent?.emit(false);
        this._dialogRef?.close();
      })
    ).subscribe({
      next: (result:EclipseDocumentsResponse) => {
       // showToastEvent.emit({ message: "Document created successfully", isError: false });
        showPhraseValuePopEvent.emit({documentId: result.id, documentTemplateId: selectedTemplate!.id, documentName: result.name, statusId: result.status.id});
      },
      error: (error: HttpErrorResponse) => {
        if (error?.error?.Detail)
          showToastEvent.emit({ message: error?.error.Detail, isError: true });
        else {
          showToastEvent.emit({ message: 'Something went wrong', isError: true });
        }
      }
    });
  }

  initialiseForm() {
    this.addDocumentTemplateForm = this._formBuilder.group(
      {
        fcTemplateRegion: ['', Validators.required],
        fcTemplateType: ['', Validators.required],
        fcTemplateDescription: ['', Validators.required],
        fcDocumentTemplateId: ['', Validators.required]
      });
  }
  ngOnDestroy(): void {
    this.templatesInfoSubscription$?.unsubscribe();
  }
}
