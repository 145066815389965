import { Directive, Output, EventEmitter, Input, HostBinding, HostListener } from "@angular/core";
import { DocFileDefaultSettings } from "../models/doc.default";


@Directive({
    selector: '[appIpsDocFile]',
  })
  export class IpsDocFileDirective {
    @Output() private filesValidEmitter: EventEmitter<File[]> = new EventEmitter();
    @Output() private filesInvalidEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() private filesInvalidSizeEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() private multipleUploadEmitter: EventEmitter<boolean> = new EventEmitter();
    _settings: DocFileDefaultSettings = new DocFileDefaultSettings();
    // TODO: code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Input('dnd-settings') set settings(value: DocFileDefaultSettings) {
      this._settings = { ...new DocFileDefaultSettings(), ...value };
    }
    disableDirective = false;
    // TODO: code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Input('isDisabled') set isDisabled(value: any) {
      this.disable = this.disableDirective = !!value;
    }
    @HostBinding('class.disabledDropZone') disable = this.disableDirective;

    @HostListener('dragover', ['$event'])
    // TODO: code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public onDragOver(evt: { preventDefault: () => void; stopPropagation: () => void; }) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    @HostListener('dragleave', ['$event'])
    // TODO: code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public onDragLeave(evt: { preventDefault: () => void; stopPropagation: () => void; }) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    @HostListener('drop', ['$event'])
    // TODO: lint code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public onDrop(evt: { preventDefault: () => void; stopPropagation: () => void; }) {
      evt.preventDefault();
      evt.stopPropagation();
      if (this.isDisabled) {
        return;
      }
      this.readFiles(evt);
    }

    // TODO: lint code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    readFiles(event: any): void {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        if (this._settings.Multiple || (!this._settings.Multiple && files.length === 1)) {
          this.checkforValidAndInvalidFiles(files);
        } else {
          this.multipleUploadEmitter.emit(true);
        }
      }
    }

    // TODO: lint code review
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    checkforValidAndInvalidFiles(files: any): void {
      const validFiles: File[] = [];
      const invalidFiles: File[] = [];
      const invalidFileSize: File[] = [];
      let allowedMaxFileSize: any;
      if (this._settings.ValidateMaxFileSize) {
        allowedMaxFileSize = "73400320";
      }

      for (let i = 0; i <= files.length - 1; i++) {
        const file = files[i];
        const ext = file.name.split('.')[file.name.split('.').length - 1];
        if (this._settings.AllowedExtensions.lastIndexOf(ext.toUpperCase()) !== -1) {
          if (file.size > allowedMaxFileSize) {
            invalidFileSize.push(file);
            break;
          } else {
            validFiles.push(file);
          }
        } else {
          invalidFiles.push(file);
          break;
        }
      }
      if (invalidFiles.length > 0) {
        this.filesInvalidEmitter.emit(true);
      } else {
        if (invalidFileSize.length > 0) {
          this.filesInvalidSizeEmitter.emit(true);
        } else if (validFiles.length > 0) {
          this.filesValidEmitter.emit(validFiles);
        }
      }
    }
  }
