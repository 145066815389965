import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { refreshNoteList } from '@igps/top-utility';
import { NotesService } from '../../../../services/notes-service';
import { TaskBasicInfoDataModel } from 'src/app/models/taskBasicInfoDataModel';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, finalize } from 'rxjs';
import { showSpinnerEvent, showToastEvent } from '../../../app.component';
@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNotesComponent implements OnInit, OnDestroy, AfterViewInit {
  public subscribeObjectList: Subscription[] = [];
  showSpinner: boolean = false;
  isError!: boolean;
  errorMessage!: string;
  public notes!: number;
  public notesTo!: number;
  public maxLengthAllowedChars = 3000;
  public selectAllKeyValue = 65;
  public deleteKeyValue = 46;
  public backSpaceKeyValue = 8;
  public enterKeyValue = 13;
  public noteHeader = 'Add Note';
  public disbaleSaveBtn = false;
  @ViewChild('divValue') private divValue: any;
  public notesData = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { notes: string; basicTaskInfo: TaskBasicInfoDataModel; },
    public ref: ChangeDetectorRef,
    public noteService: NotesService) {

  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-unused-expression
    this.divValue ? (this.divValue.nativeElement.innerText = this.notesData) : '';
  }
  ngAfterViewInit(): void {
    if (this.data) {
      // tslint:disable-next-line:no-unused-expression
      this.divValue ? (this.divValue.nativeElement.innerText = this.data.notes) : '';// check
      this.notesData = this.divValue.nativeElement.innerText;
      this.noteHeader = 'Add Note';
      this.ref.detectChanges();
    }
  }
  saveNotes(): void {
    const body: { Notes: string } = {
      Notes: this.notesData.trim().replace(/\n\n/g, '\n')
    };
    showSpinnerEvent.emit(true);
    this.noteService.saveNotes(this.data.basicTaskInfo.id, body).pipe(
      finalize(() => {
        showSpinnerEvent.emit(false);
      })
    ).subscribe({
      next: () => {
        this.divValue.nativeElement.innerText = "";
        showToastEvent.emit({ message: "Note Added Successfully.", isError: false });
        refreshNoteList.emit(true);
      },
      error: (error: HttpErrorResponse) => {
        showToastEvent.emit({ message: error?.error.Detail, isError: true });
      }
    });
  }
  preventInvalidChar(event: any): any {
    if (event.key === '`' || event.key === '~' || event.key === '{' || event.key === '}' || event.key === '|') {
      return event.preventDefault();
    }
  }
  maxLengthLimit(event: any): any {
    if (event.ctrlKey && event.which === this.selectAllKeyValue) {
      return true;
    }
    if (
      event.which !== this.backSpaceKeyValue &&
      event.which !== this.deleteKeyValue &&
      this.notesData &&
      this.notesData.length >= this.maxLengthAllowedChars
    ) {
      return event.preventDefault();
    } else if (event.which === this.enterKeyValue && this.notesData && this.notesData.length >= this.maxLengthAllowedChars - 1) {
      return event.preventDefault();
    }
  }
  checkForInvalidPastedChars(event: ClipboardEvent): any {
    const invalidCharList = ['{', '}', '`', '~', '|'];
    const ClipboardData = event.clipboardData;
    const str = ClipboardData?.getData('text');
    if (invalidCharList.some((c) => str?.includes(c))) {
      return event.preventDefault();
    }
  }
  /*
  *  It will check max length of copy paste data
  */
  checkForMaxLengthPastedChars(event: ClipboardEvent): any {
    const ClipboardData = event.clipboardData;
    const str = ClipboardData?.getData('text');
    if (str && this.notesData && this.notesData.length + str.length >= this.maxLengthAllowedChars) {
      const remainingAllowedLength = this.maxLengthAllowedChars - this.notesData.length;
      if (str.length > remainingAllowedLength) {
        // tslint:disable-next-line:no-unused-expression
        this.divValue ? (this.divValue.nativeElement.innerText = this.notesData + str.substring(0, remainingAllowedLength)) : '';
        this.notesData = this.divValue.nativeElement.innerText;
        return event.preventDefault();
      }
    } else if (str && str.length >= this.maxLengthAllowedChars) {
      // tslint:disable-next-line:no-unused-expression
      this.divValue ? (this.divValue.nativeElement.innerText = str.substring(0, this.maxLengthAllowedChars)) : '';
      this.notesData = this.divValue.nativeElement.innerText;
      return event.preventDefault();
    }
  }
  public ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }

}
