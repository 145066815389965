<article class="dialog__header mat-dialog-title">
    <h3 mat-dialog-title> Add Document Template </h3>
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>

<mat-dialog-content class="mat-typography mat-dialog-content modal-popup-add-template">
    <form [formGroup]="addDocumentTemplateForm">
        <reactive-filter-select class="ig2" label="Region" formControlName="fcTemplateRegion" [options]="templateRegions" nameField="name" idField="id">
        </reactive-filter-select>
        <reactive-filter-select class="ig2" label="Template Type" formControlName="fcTemplateType" [options]="templateTypes" nameField="description" idField="id">
        </reactive-filter-select>
        <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
            <mat-label id="lblTemplateDescription" class="mat-label-wrap">Template Desc</mat-label>
            <input class="mat-input-element-show-placeholder" id="inputTemplateDescription" matInput formControlName="fcTemplateDescription" placeholder="Template Description" />
        </mat-form-field>
        <button class="ig2 button--tertiary button--small" type="button" [disabled]="!getNumberOfInvalidControls()" mat-button (click)="onSearchFastFastDocument()">Find FAST Doc Templates</button>
        <p class="text-warning" *ngIf="showWarning">** No Templates found **</p>
        <div class="templateName-select">
            <reactive-filter-select class="ig2 " label="Select Document Templates in FAST" formControlName="fcDocumentTemplateId" [options]="templates" nameField="description"></reactive-filter-select>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions ">
    <button class="ig2 button button--no-border button--small " mat-button mat-dialog-close>Cancel</button>
    <button class="ig2 button button--primary button--small " mat-button cdkFocusInitial [disabled]="!addDocumentTemplateForm.valid" (click)="onSubmit()">
        <img  id="imgPlusIcon " src="/assets/icons/icon-plus-light.svg "
        alt="plus-icon " /> Add Document Template
    </button>
</mat-dialog-actions>