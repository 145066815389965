<section class="doc-table">
    <section class="doc-table__upper">
        <div class="doc-table__attach" *ngIf="showEmailAttach">
            <button mat-flat-button class="attchemailbtn ig2 button--small" (click)="onAttachEmail()"><lib-icon
                    class="cursor__pointer ig-input-field__icon-after" name="attachment" size="small"></lib-icon> Attach
                Email</button>
            <!-- <button mat-flat-button class="attchemailbtn ig2 button--small"><lib-icon
                    class="cursor__pointer ig-input-field__icon-after" name="file_pdf" size="small"></lib-icon> Image
                Settlement Statement</button> -->
        </div>
        <div class="doc-table__title">{{ title }}</div>
    </section>

    <section class="doc-table__lower">
        <table class="doc-table__data-table" matSort (matSortChange)="sortData($event)" [matSortActive]="'documentName'"
            [matSortDirection]="'asc'" matSortDisableClear>
            <thead>
                <tr>
                    <th mat-sort-header="documentName" class="th-width">
                        Document Name</th>
                    <th mat-sort-header="createdDate">Date and Time</th>
                    <th *ngIf="tableConfiguration.fileTypeCol" mat-sort-header="documentExtensionType"
                        class="th-width-filetype">
                        File Type</th>
                    <!-- <th *ngIf="tableConfiguration.statusTypeCol">Status Type</th> -->
                    <th *ngIf="tableConfiguration.documentType" mat-sort-header="documentType">Document Type
                    </th>
                    <th *ngIf="tableConfiguration.uploadedBy" mat-sort-header="uploadedBy">Uploaded By
                    </th>
                    <th [ngClass]="{ menu: !menuConfiguration.doc_icons }"></th>
                </tr>
            </thead>
            <tbody *ngIf="sortedData && sortedData.length>0">
                <tr *ngFor="let document of sortedData">
                    <td class="titlerole igps-text-overflow">
                        <span class="checkbox" *ngIf="tableConfiguration.checkbox">
                            <mat-checkbox class="ig2"></mat-checkbox>
                        </span> <span #tooltip="matTooltip" [matTooltip]="document.documentName"
                            [matTooltipPosition]="position.value!">
                            {{ document.documentName }}
                        </span>
                    </td>
                    <td class="phonefax">
                        {{document.createdDate | convertTime: "us/pacific": timezone.value : "default" : true : true }}
                        <!-- {{ document.createdDate | date:'MMM dd' }} <span>at </span>{{ document.createdDate | date:'shortTime' }} -->
                    </td>

                    <td *ngIf="tableConfiguration.fileTypeCol">

                        <span *ngIf="document.documentExtensionType != 'Created'">
                            <lib-icon (click)="previewDoc(document)"
                                [tooltip]="document.documentExtensionType.split('.')[1]"
                                [name]="document.documentExtensionType.split('.')[document.documentExtensionType.split('.').length - 1].toLowerCase() === 'msg' ? 'mail' : 'file_'+ document.documentExtensionType.split('.')[document.documentExtensionType.split('.').length - 1].toLowerCase()">
                            </lib-icon>
                        </span>
                        <span *ngIf="document.documentExtensionType == 'Created'">{{
                            document.status.description
                            }}</span>
                    </td>

                    <!-- <td *ngIf="tableConfiguration.statusTypeCol">
                        <lib-icon *ngIf="document.statusTypeText.toUpperCase() === 'PDF'"
                            class="ig-input-field__icon-after" name="file_pdf" size="standard" tooltip="PDF"></lib-icon>
                        <lib-icon *ngIf="document.statusTypeText.toUpperCase() === 'PNG'"
                            class="ig-input-field__icon-after" name="file_png" size="standard" tooltip="PNG"></lib-icon>
                        <span
                            *ngIf="document.statusTypeText.toUpperCase() !== 'PNG' && document.statusTypeText.toUpperCase() !== 'PDF'">{{
                            document.statusTypeText
                            }}</span>
                    </td> -->

                    <td *ngIf="tableConfiguration.documentType">{{ document.documentType }}</td>
                    <td *ngIf="tableConfiguration.uploadedBy" class="email">{{ document.uploadedBy }}</td>
                    <td [ngClass]="{ menu: !menuConfiguration.doc_icons }">
                        <div class="doc-table__icon">
                            <span *ngIf="menuConfiguration.doc_icons">
                                <span *ngIf="document.documentExtensionType.toUpperCase() === '.PDF'">
                                    <lib-icon *ngIf="menuConfiguration.onViewPDF" (click)="onViewPDFClick(document)"
                                        class="cursor__pointer ig-input-field__icon-after" name="file_pdf" size="small"
                                        tooltip="View PDF"></lib-icon>
                                </span>

                                <lib-icon *ngIf="menuConfiguration.onDetails" (click)="onDetailsClick($event)"
                                    class="cursor__pointer ig-input-field__icon-after" name="document_view" size="small"
                                    tooltip="View Details"></lib-icon>

                                <lib-icon *ngIf="menuConfiguration.onRemove" (click)="onRemoveClick(document)"
                                    class="cursor__pointer ig-input-field__icon-after" name="trash" size="small"
                                    tooltip="Delete"></lib-icon>
                            </span>

                            <span *ngIf="!menuConfiguration.doc_icons">
                                <button class="ig2 mat-menu-overflow-menu" mat-button [matMenuTriggerFor]="menu">
                                    <lib-icon class="cursor__pointer ig-input-field__icon-after" name="kebab"
                                        size="standard"></lib-icon>
                                </button>

                                <mat-menu #menu="matMenu" class="ig2 mat-menu-panel-overflow-menu">

                                    <button mat-menu-item (click)="onRemoveClick(document)"
                                        *ngIf="document.sourceFrom=='igpsDB'">
                                        <lib-icon *ngIf="menuConfiguration.onRemove"
                                            class="cursor__pointer ig-input-field__icon-after" name="trash" size="small"
                                            theme="white"></lib-icon>Remove Document
                                    </button>
                                    <span *ngIf="document.documentExtensionType !== 'Created'">
                                        <button mat-menu-item [matMenuTriggerFor]="list">
                                            <lib-icon class="cursor__pointer ig-input-field__icon-after"
                                                name="plus_circle" size="small" theme="white"></lib-icon>Add to
                                            Associate Package
                                            <span>&nbsp;</span>
                                        </button>

                                        <mat-menu #list="matMenu" class="ig2 mat-menu-panel-overflow-menu">
                                            <span *ngFor="let x of DocPakageList">
                                                <button mat-menu-item (click)="onAssociatePackageClick(document,x)">
                                                    {{x.name}}
                                                </button>
                                            </span>

                                        </mat-menu>
                                    </span>
                                    <button mat-menu-item (click)="onViewPDFClick(document)">
                                        <lib-icon class="cursor__pointer ig-input-field__icon-after doc-details-icon"
                                            name="file_pdf" size="small" theme="white"></lib-icon>Document
                                        Details
                                    </button>


                                    <span *ngIf="document.documentExtensionType === 'Created'">
                                        <button mat-menu-item
                                            (click)="onViewPhraseDetails(document.documentId, document.documentTemplateId, document.documentName, document.status.id)">
                                            <lib-icon *ngIf="menuConfiguration.onDetails"
                                                class="cursor__pointer ig-input-field__icon-after" name="document_view"
                                                size="small" theme="white"></lib-icon>View Phrases
                                        </button>
                                    </span>
                                    <!-- <button mat-menu-item (click)="onDetailsClick($event)" *ngIf="false">
                                        <lib-icon *ngIf="menuConfiguration.onDetails"
                                            class="cursor__pointer ig-input-field__icon-after" name="document_view"
                                            size="small" theme="white"></lib-icon>View Phrases
                                    </button> -->


                                </mat-menu>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="tableRowData && tableRowData.length==0">
                <tr>
                    <td class="marginleft"><span>No records found</span></td>
                </tr>

            </tbody>
        </table>
    </section>

    <section class="doc-table__upload" id="dropzone" appIpsDocFile
        (filesValidEmitter)="onValidFileUpload($any($event), false, 0, $any($event), true)"
        (filesInvalidSizeEmitter)="onInvalidFileSizeUpload()" (filesInvalidEmitter)="onInvalidFileUpload()">
        <div *ngIf="tableConfiguration?.showOption" class="doc-table__upload--select">
            <mat-form-field class="ig2 mat-form-field-no-animation-condensed white doc-field" appearance="fill">
                <mat-select class="ig2" placeholder="Select a Document Template" (selectionChange)="onSelectTemplate()"
                    [(ngModel)]="selectedTemplate">
                    <mat-option class="mat-option-white" *ngFor="let template of templates" [value]="template">{{
                        template.documentTemplateDescription }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <article class="doc-table__upload--controls">
            <div class="doc-table__upload--text">Drop files here or</div>

            <article class="doc-table__upload--buttons">
                <button mat-flat-button class="ig2 button button--secondary button--xsmall"
                    (filesValidEmitter)="onValidFileUpload($any($event), false, 0)">
                    <!-- <lib-icon class="cursor__pointer ig-input-field__icon-after" name="save" size="small"></lib-icon> -->
                    <img class="button__icon" src="./assets/icons/icon-computer-dark.svg" alt="computer icon">
                    <span class="hidden-small">Upload Files</span>
                    <input #fileInput id="files" name="files" type="file" class="fileInput"
                        (filesValidEmitter)="onValidFileUpload($any($event), false, 0)"
                        (change)="onFileChange($event)" />
                </button>

                <button mat-flat-button class="ig2 button button--secondary button--xsmall"
                    (click)="openBrowserDocRepoPopup()">
                    <lib-icon class="cursor__pointer ig-input-field__icon-after" name="search" size="small"></lib-icon>
                    <span class="hidden-small"> Browse Document Repository</span>
                </button>
            </article>
        </article>
    </section>
    <!-- <section class="doc-table__upload">
        <article class="document-upload__drop-zone" appIpsDocFile
            (filesValidEmitter)="onValidFileUpload($any($event), false, 0, $any($event), true)"
            (filesInvalidSizeEmitter)="onInvalidFileSizeUpload()" (filesInvalidEmitter)="onInvalidFileUpload()">
            <img class="document-upload__icon-upload" src="./assets/icons/icon-save-dark.svg" alt="upload icon">
            <h4 class="drag-label-color">Drag files here or:</h4>
            <article class="document-upload__button-wrap">
                <button mat-flat-button class="ig2 button button--secondary button--xsmall">
                    <img class="button__icon" src="./assets/icons/icon-folder-dark.svg" alt="folder icon">Add from Doc
                    Repository
                </button>
                <button mat-flat-button class="ig2 button button--secondary button--xsmall"
                    (filesValidEmitter)="onValidFileUpload($any($event), false, 0)">
                    <img class="button__icon" src="./assets/icons/icon-computer-dark.svg" alt="computer icon">Upload
                    from
                    Device
                    <input id="files" name="files" type="file" class="fileInput"
                        (filesValidEmitter)="onValidFileUpload($any($event), false, 0)"
                        (change)="onFileChange($event)" />
                </button>
            </article>

        </article> -->
</section>