import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, NgZone, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetTaskBasicInfo, TaskBasicInfoEvent } from '@igps/file-info-header';
import { Subscription, forkJoin, finalize, Observable } from 'rxjs';
import { Source, TaskBasicInfoDataModel } from '../../../models/taskBasicInfoDataModel';
import { Template } from '../../../models/template';
import { DocumentService } from '../../../services/document.service';
import { getTimezone, timezone$, ipsWsMessages$ } from '@igps/client-root-config';
import { DocumentDetailPopupComponent } from '../document-detail-popup/document-detail-popup.component';
import { IMessage } from '../../../models/wsmessage';
import { Events } from '../../../models/Events';
import { DocumentExtensionEnum } from '../../../models/documentExtension';
import { PhraseValuePopoutData, PopoutData, PopoutModalName, POPOUT_MODALS } from '../../../services/popout.tokens';
import { PopoutService } from '../../../services/popout.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { Sort } from '@angular/material/sort';
import { AddDocumentComponent } from '../add-document/add-document.component';
import { showSpinnerEvent, showToastEvent } from '../../../app.component';
import { BrowseDocRepoPopupComponent } from '../browse-doc-repo-popup/browse-doc-repo-popup.component';
import { TemplateDetailPopupComponent } from '../template-detail-popup/template-detail-popup.component';
import { DialogOverviewExampleDialog } from '../remove-document/removeDocument-popup';
import { selectedTaskId } from '@igps/top-utility';
import { ImageDetailPopupComponent } from '../image-detail-popup/image-detail-popup.component';
import { DocumentDetailsOnRemove } from '../../../models/documentRepository';
import { GetSelectedProcess, onChangeProcess } from '@igps/top-utility';
import { Process } from 'src/models/Process';
import { AttachEmailPopupComponent } from '../../attach-email-popup/attach-email-popup.component';

export const ReloadDocumentListEvent: EventEmitter<void> = new EventEmitter<void>();

export const showPhraseValuePopEvent: EventEmitter<{ documentId: number; documentTemplateId: number; documentName: string; statusId: number }> = new EventEmitter<{ documentId: number; documentTemplateId: number; documentName: string; statusId: number }>();

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss'],
})


export class DocUploadComponent implements OnInit, OnDestroy {
  readonly addDocumentText = 'Add a document';
  document = new FormControl('', [Validators.required]);
  tableRowData: any = [];
  menuConfiguration = {
    doc_icons: false,
    onViewPDF: true,
    onDetails: true,
    onRemove: true,
  };
  tableConfiguration = {
    checkbox: false,
    fileTypeCol: true,
    statusTypeCol: false,
    documentType: true,
    showOption: true,
    uploadedBy: false,
  };

  templates: Template[] = [];
  title = 'Documents';
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  basicTaskInfo!: TaskBasicInfoDataModel;
  taskInformationSubscription$!: Subscription;
  selectedTemplate!: Template | undefined;
  allowedExtensions: string[] = [
    'TIF',
    'TIFF',
    'PDF',
    'JPG',
    'JPEG',
    'DOC',
    'DOCX',
    'XLS',
    'XLSX',
    "MSG"
  ];
  maxFileSize: number = 30 * 1024 * 1024; // 30 MB

  WebSockectSubscription!: Subscription;
  reloadDocumentListEventSubscription!: Subscription;
  @ViewChild('myModal') myModal: any;
  @ViewChild('fileInput') fileInput!: any;
  sortedData: any;
  DocPakageList: any = [];
  timezoneSubscription!: Subscription;
  onSelectedProcessChangeSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  taskSource: any;
  showEmailAttach: boolean = false;
  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private popoutService: PopoutService,
    private zone: NgZone
  ) { }

  public ngOnDestroy(): void {
    this.taskInformationSubscription$?.unsubscribe();
    this.WebSockectSubscription?.unsubscribe();
    this.reloadDocumentListEventSubscription?.unsubscribe();
    this.timezoneSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.zone.run(() => {
        this.timezone = timezone;
      });
    });
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe((data) => {
      if (this.basicTaskInfo?.id !== data.taskinfo?.id) {
        this.basicTaskInfo = data.taskinfo;
        if (this.basicTaskInfo) {
          if (this.basicTaskInfo.emailId) {
            this.showEmailAttach = true;
          } else {
            this.showEmailAttach = false
          }
          this.getDocumentsAndTemplates();
        }
      }
    });

    showPhraseValuePopEvent.subscribe((result) => {
      this.onViewPhraseDetails(result.documentId, result.documentTemplateId, result.documentName, result.statusId)
    })
    if (!this.basicTaskInfo) {
      this.basicTaskInfo = GetTaskBasicInfo();
      if (this.basicTaskInfo) {
        if (this.basicTaskInfo.emailId) {
          this.showEmailAttach = true;
        } else {
          this.showEmailAttach = false
        }
        this.getDocumentsAndTemplates();
      }
    }
    this.document.setErrors({ incorrect: true });
    this.WebSockectSubscription = ipsWsMessages$.subscribe((data: IMessage) => {
      if (data) {
        if (Events.DocumentEvent.includes(data?.EventName?.toLowerCase())) {
          this.getRefreshedDocuments(true);
          this.onSelectedProcessChangeSubscription = onChangeProcess.subscribe((process: Process) => {
            this.taskSource = process.taskSource;
          });

          if (this.taskSource === undefined) {
            const process = GetSelectedProcess();
            if (process?.id) {
              this.taskSource = process.taskSource
            }
          }
        }
      }
    });
    this.reloadDocumentListEventSubscription = ReloadDocumentListEvent.asObservable().subscribe(() => this.getRefreshedDocuments(false))
  }

  getRefreshedDocuments(isBackground: boolean) {
    this.zone.run(() => {
      if (!isBackground) {
        showSpinnerEvent.emit(true);
      }
      if (this.basicTaskInfo.sourceId === Source.Ignite) {
        this.documentService.getDocmentsList(this.basicTaskInfo.fastFileId, this.basicTaskInfo.externalSystemTaskId)
          .pipe(
            finalize(() => {
              if (!isBackground) {
                showSpinnerEvent.emit(false)
              }
            })
          ).subscribe({
            next: (result) => {
              this.tableRowData = result;
              this.sortedData = this.tableRowData.slice();
              const sortrequest: Sort = {
                active: 'documentName',
                direction: 'asc'
              }
              this.sortData(sortrequest)
            },
            error: (error: HttpErrorResponse) => {
              showToastEvent.emit({
                message: error?.error?.Detail,
                isError: true,
              });
            },
          });

      }
      else {
        this.documentService.getDocmentsListInternalTask(this.basicTaskInfo.fastFileId, this.basicTaskInfo.internalGroupId)
          .pipe(
            finalize(() => {
              if (!isBackground) {
                showSpinnerEvent.emit(false)
              }
            })
          ).subscribe({
            next: (result) => {
              this.tableRowData = result;
              this.sortedData = this.tableRowData.slice();
              const sortrequest: Sort = {
                active: 'documentName',
                direction: 'asc'
              }
              this.sortData(sortrequest)
            },
            error: (error: HttpErrorResponse) => {
              showToastEvent.emit({
                message: error?.error?.Detail,
                isError: true,
              });
            },
          });
      }



      this.documentService.getDocumentPackage(
        this.basicTaskInfo.fastFileId).subscribe(res => {
          if (res) {
            this.DocPakageList = res;
          }
        })
    });
  }

  getDocumentsAndTemplates() {
    this.zone.run(() => {
      var docListApiForTask: Observable<Template[]> = new Observable<Template[]>();
      if (this.basicTaskInfo.sourceId === Source.Ignite) {
        const docListApi = this.documentService.getDocmentsList(
          this.basicTaskInfo.fastFileId,
          this.basicTaskInfo.externalSystemTaskId
        );
        docListApiForTask = docListApi;
      }
      if (this.basicTaskInfo.sourceId === Source.IgnitePS) {
        const docListInternalTaskApi = this.documentService.getDocmentsListInternalTask(
          this.basicTaskInfo.fastFileId,
          this.basicTaskInfo.internalGroupId
        );
        docListApiForTask = docListInternalTaskApi;

      }

      const templateApi = this.documentService.getTemplates(
        this.basicTaskInfo.processId
      );
      const packageApi = this.documentService.getDocumentPackage(
        this.basicTaskInfo.fastFileId)
      showSpinnerEvent.emit(true);
      forkJoin([docListApiForTask, templateApi, packageApi])
        .pipe(finalize(() => {
          showSpinnerEvent.emit(false);
        }
        ))
        .subscribe({
          next: (result) => {
            this.tableRowData = result[0];
            this.sortedData = this.tableRowData.slice()
            const sortrequest: Sort = {
              active: 'documentName',
              direction: 'asc'
            }
            this.sortData(sortrequest);
            this.templates = [];
            this.templates = result[1];
            this.templates.push({
              id: '',
              listingTemplateGroupId: '',
              processId: '',
              processName: '',
              templateRegionId: 0,
              templateRegionName: '',
              templateTypeId: 0,
              templateTypeName: '',
              documentTemplateId: 0,
              documentTemplateName: '',
              documentTemplateDescription: this.addDocumentText,
              statusId: 0,
              createdDateUTC: '',
              createdBy: {
                id: '',
                name: ''
              },
              lastUpdatedBy: {
                id: '',
                name: ''
              },
              lastUpdateDateUTC: ''
            });
            this.DocPakageList = [];
            this.DocPakageList = result[2];
          },
          error: (error: HttpErrorResponse) => {
            showToastEvent.emit({
              message: error?.error?.Detail,
              isError: true,
            });
          },
        });
    });
  }

  previewDoc(docDetails: any) {
    showSpinnerEvent.emit(true);
    this.documentService.previewDocument(this.basicTaskInfo.fastFileId, docDetails.documentId, docDetails.documentExtensionType.split('.')[1], docDetails.fileSize).subscribe((res: any) => {
      // if (res && res.bytes) {
      if (res) {
        if (docDetails.documentExtensionType.split('.')[1] === DocumentExtensionEnum.PDF) {
          // var blob = this.b64toBlob(res.bytes, "application/pdf");
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = URL.createObjectURL(res);
          showSpinnerEvent.emit(false);
          window.open(url, '_blank')

        }
        else {
          const fileNameExtension = `${docDetails.documentName}.${docDetails.documentExtensionType.split('.')[1].toLowerCase()}`
          // const mimetype = this.getmimeType(docDetails.documentExtensionType.split('.')[1]);
          // var blob = this.b64toBlob(res.bytes, mimetype);
          const blobURL = URL.createObjectURL(res);
          const a = document.createElement("a");
          a.href = blobURL;
          //   window.open(blobURL)
          a.download = String(fileNameExtension);
          a.click();
          showSpinnerEvent.emit(false);
          URL.revokeObjectURL(blobURL);
          a.remove();
        }
      } else {
        showSpinnerEvent.emit(false);
      }
    }, err => {
      // tslint:disable-next-line:no-console
      console.log(err);
      showSpinnerEvent.emit(false);
    })
  }
  b64toBlob(b64Data: any, contentType: any) {
    contentType = contentType || '';
    const sliceSize = 512;

    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  getmimeType(documentExtensionType: any) {
    switch (documentExtensionType) {
      case 'DOC':
        return 'application/msword';
        break;
      case 'DOCX':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'XLS':
        return 'application/vnd.ms-excel';
        break;
      case 'XLSX':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break
      case 'TIF':
      case 'TIFF':
        return 'image/tiff';
        break
      case 'JPEG':
      case 'JPG':
        return 'image/jpeg';
        break;
      case 'MSG':
        return 'application/vnd.ms-outlook';
        break;
      default:
        return false
        break;
    }
  }
  onViewPDFClick(document: any): void {
    if (document.documentExtensionType === "Created") {
      const dialogRef = this.dialog.open(TemplateDetailPopupComponent, {
        panelClass: 'ig2',
        width: '1040px',
        disableClose: true,
        data: {
          documentID: document.documentId,
          fileID: this.basicTaskInfo.fastFileId
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        // do nothing.
      });
    }
    else {// Image Document
      const dialogRef = this.dialog.open(ImageDetailPopupComponent, {
        panelClass: 'ig2',
        width: '1040px',
        disableClose: true,
        data: {
          "document": document,
          taskInfo: this.basicTaskInfo
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        // do nothing.
      });
    }
  }

  onDetailsClick($event: Event): void {
    // tslint:disable-next-line:no-console
    console.log($event, 'Document Details');
  }

  onRemoveClick(docdetails: DocumentDetailsOnRemove): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      panelClass: 'ig2',
      width: '450px',
      data: {
        documentDetails: docdetails,
        taskInfo: this.basicTaskInfo
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      // tslint:disable-next-line:no-console
      console.log(result)
    });

  }

  onAssociatePackageClick(document: any, x: any) {
    showSpinnerEvent.emit(true);
    const isexit = this.checkexistingfile(document, x);
    if (isexit) {
      if (document.documentExtensionType === ".PDF" || (document.documentExtensionType === ".TIFF" || document.documentExtensionType === ".TIF")) {
        const requestbody = {
          "taskId": this.basicTaskInfo.id,
          "Name": x.name,
          "AssociatedDocuments": [{
            "PackagedDocument": null,
            "PackagedImage": {
              "Id": document.documentId,
              "Name": document.documentName
            },
            "SequenceNumber": 0
          }]
        }
        this.documentService.associatepackagetofile(this.basicTaskInfo.fastFileId, x.id, requestbody).subscribe(res => {
          if (res) {
            showSpinnerEvent.emit(false);
            selectedTaskId.emit(this.basicTaskInfo.id);
            ReloadDocumentListEvent.emit();
            showToastEvent.emit({
              message:
                "documents added to :'" + x.name + "' package successfully.",
              isError: false,
            });
          }
        }, error => {
          showSpinnerEvent.emit(false);
          showToastEvent.emit({ message: error?.error?.Detail, isError: true })

        })

      } else {
        showSpinnerEvent.emit(false);
        showToastEvent.emit({
          message:
            "File cannot be added to an Associate Pacakge: " + x.name + "<br/> Please select valid documents.",
          isError: true,
        });
      }
    } else {
      showSpinnerEvent.emit(false);
      showToastEvent.emit({
        message:
          "This image already exists in this package - " + x.name,
        isError: true,
      });
    }

  }
  checkexistingfile(documentx: any, x: any) {
    let valid = true;
    if (x.associatedDocuments && x.associatedDocuments.length > 0) {
      x.associatedDocuments.forEach((res: any) => {
        if (res.packagedImage && res.packagedImage.id === documentx.documentId) {
          valid = false;
        }
      })
    }
    return valid;
  }
  onViewPhraseDetails(documentId: number, documentTemplateId: number, documentName: string, statusId: number) {
    this.openPhraseValuePopout(documentId, documentTemplateId, documentName, statusId);
  }

  public showAddDocumentPopup() {
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      panelClass: 'ig2',
      width: '520px',
      data: {
        taskInfo: this.basicTaskInfo
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      // do nothing.
      (this.selectedTemplate as any) = undefined;
    });
  }
  public openDocumentDetailsDialog(_fileList: File[]): void {
    this.zone.run(() => {

      const dialogRef = this.dialog.open(DocumentDetailPopupComponent, {
        panelClass: 'ig2',
        width: '1040px',
        disableClose: true,
        data: {
          taskInfo: this.basicTaskInfo,
          file: _fileList[0],
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        // do nothing.
        this.fileInput.nativeElement.value = '';
      });
    });
  }
  public onValidFileUpload(
    _fileList: File[],
    _isReadOnly: boolean,
    _taskDocumentId?: number,
    _$event?: Event,
    _isDragAndDrop?: boolean
  ): void {
    const files = _fileList;
    if (files === null) {
      return;
    }
    if (files.length > 1) {
      this.onMultipleFilesUpload();
      this.fileInput.nativeElement.value = '';
      return;
    }
    const ext = files[0].name.split('.')[files[0].name.split('.').length - 1];
    if (this.allowedExtensions.lastIndexOf(ext.toUpperCase()) < 0) {
      this.onInvalidFileUpload();
      this.fileInput.nativeElement.value = '';
      return;
    }
    if (files[0].size === 0) {
      this.onZeroFileSizeUpload();
      this.fileInput.nativeElement.value = '';
      return;
    }
    if (files[0].size > this.maxFileSize) {
      this.onInvalidFileSizeUpload();
      this.fileInput.nativeElement.value = '';
      return;
    }
    const convertedFiles = files as unknown as File[];
    this.openDocumentDetailsDialog(convertedFiles);
  }
  public onFileChange($event: Event): void {
    if ($event.target && ($event.target as HTMLInputElement).files) {
      const files = ($event.target as HTMLInputElement).files;
      const convertedFiles = files as unknown as File[];
      this.zone.run(() => {
        this.onValidFileUpload(convertedFiles, false, 0, $event, false);
      });
    }
  }
  public onInvalidFileUpload(): boolean {
    // throw user a message that format is not applicable
    showToastEvent.emit({
      message:
        'File type is invalid. It must have an extension of .tif .tiff .pdf .jpg .jpeg .doc .docx .xls .xlsx .msg',
      isError: true,
    });
    return false;
  }

  public onInvalidFileSizeUpload(): boolean {
    // throw user a message that file size is grater than MaxFileSize of the application
    showToastEvent.emit({
      message:
        '<b>Document Upload Failed</b>. The document exceeds the allowed limit. Please select a file that is less than 30 MB.',
      isError: true,
    });
    return false;
  }

  public onZeroFileSizeUpload(): boolean {
    // throw user a message that file size is grater than MaxFileSize of the application
    showToastEvent.emit({
      message:
        '<b>Document Upload Failed</b>. The document is 0 Bytes. Please select a file that is more than 0 Bytes.',
      isError: true,
    });
    return false;
  }

  public onMultipleFilesUpload(): boolean {
    showToastEvent.emit({
      message: 'Multiple document uploads are not supported',
      isError: true,
    });
    return false;
  }

  onAddDocumentTemplate() {
    if (this.selectedTemplate!.documentTemplateDescription === this.addDocumentText) {
      this.showAddDocumentPopup();
    }
  }

  onSelectTemplate() {
    if (this.selectedTemplate!.documentTemplateDescription === this.addDocumentText) {
      this.showAddDocumentPopup();
    }
    else {
      showSpinnerEvent.emit(true);
      this.documentService
        .addTemplateDocument(
          this.basicTaskInfo.id,
          this.basicTaskInfo.fastFileId,
          this.selectedTemplate!.templateRegionId,
          this.selectedTemplate!.documentTemplateName,
          this.selectedTemplate!.templateTypeId,
          this.selectedTemplate!.documentTemplateId
        )
        .pipe(finalize(() => {
          showSpinnerEvent.emit(false);
          this.selectedTemplate = undefined;
        }))
        .subscribe({
          next: (result) => {
            selectedTaskId.emit(this.basicTaskInfo.id);
            ReloadDocumentListEvent.emit();
            this.openPhraseValuePopout(result.id, this.selectedTemplate!.documentTemplateId, result.name, result.status.id);
          },
          error: (error: HttpErrorResponse) => {
            showToastEvent.emit({ message: error?.error?.Detail, isError: true });
          },
        });
    }
  }

  openPhraseValuePopout(documentId: number, documentTemplateId: number, documentName: string, statusId: number) {
    const modalData: PopoutData<PhraseValuePopoutData> = {
      modalName: PopoutModalName.phraseValues,
      basicTaskInfo: this.basicTaskInfo,
      data: {
        "documentId": documentId,
        "documentTemplateId": documentTemplateId,
        "statusId": statusId,
        "documentName": documentName
      }
    };
    if (!this.popoutService.isPopoutWindowOpen(PopoutModalName.phraseValues)) {
      this.popoutService.openPopoutModal(modalData);
    } else {
      POPOUT_MODALS[PopoutModalName.phraseValues].outlet.detach();
      const injector = this.popoutService.createInjector(modalData);
      const componentInstance = this.popoutService.attachPhraseValueContainer(POPOUT_MODALS[PopoutModalName.phraseValues].outlet, injector);
      POPOUT_MODALS[PopoutModalName.phraseValues].componentInstance = componentInstance;
      this.popoutService.focusPopoutWindow(PopoutModalName.phraseValues);
    }
  }



  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: Event) {
    if (this.popoutService.isPopoutWindowOpen(PopoutModalName.phraseValues)) {
      this.popoutService.focusPopoutWindow(PopoutModalName.phraseValues);
      event.returnValue = false;
      this.popoutService.focusPopoutWindow(PopoutModalName.phraseValues);
      return false;
    }
    else {
      return true;
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    if (this.popoutService.isPopoutWindowOpen(PopoutModalName.phraseValues)) {
      this.popoutService.closePopoutModal(PopoutModalName.phraseValues);
    }
  }


  sortData(sort: Sort) {
    const data = this.tableRowData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'documentName':
          return this.compare(a.documentName, b.documentName, isAsc);
        case 'documentExtensionType':
          return this.compare(a.documentExtensionType, b.documentExtensionType, isAsc);
        case 'documentType':
          return this.compare(a.documentType, b.documentType, isAsc);
        case 'createdDate':
          return this.compare(a.createdDate, b.createdDate, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public openBrowserDocRepoPopup(): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(BrowseDocRepoPopupComponent, {
        panelClass: 'ig2',
        width: '1040px',
        disableClose: true,
        data: {},
      });

      dialogRef.afterClosed().subscribe(() => {
        // do nothing.
      });
    });
  }
  openModel() {
    this.myModal.nativeElement.className = 'modal fade show';
  }
  closeModel() {
    this.myModal.nativeElement.className = 'modal hide';
  }
  onAttachEmail() {
    const dialogRef = this.dialog.open(AttachEmailPopupComponent, {
      panelClass: 'ig2',
      width: '500px',
      disableClose: true,
      data: {
        basicTaskInfo: this.basicTaskInfo
      },
    });
    dialogRef.afterClosed().subscribe();
  }
}

