import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorModel } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  readonly igpsBaseUrl = `${environment.igpsBase.apiUrl}`

  constructor(private httpClient: HttpClient) { }

  logApplicationErrors(error: ErrorModel) {
    return this.httpClient.post(`${this.igpsBaseUrl}` + `logger/log/v1`, error);
  }
}
