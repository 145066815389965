<div>
  <div class="main-container">
    <div class="notes">
      <div class="label-input-labelwrapper">
        <div class="label-div">
          <mat-label class="mat-label-wrap">Notes (Optional)</mat-label>
          <div class="mat-label-wrap popOut" (click)="onPopOutNoteClick()">
            <span>Pop-out Note</span>
            <svg viewBox="0 0 24 24" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.333 0A2.667 2.667 0 0 1 24 2.667v18.666A2.667 2.667 0 0 1 21.333 24H2.667A2.667 2.667 0 0 1 0 21.333V2.667A2.667 2.667 0 0 1 2.667 0h18.666Zm0 1.333H2.667a1.333 1.333 0 0 0-1.334 1.334v18.666a1.333 1.333 0 0 0 1.334 1.334h18.666a1.333 1.333 0 0 0 1.334-1.334V2.667a1.333 1.333 0 0 0-1.334-1.334Zm-2 2.667a.667.667 0 0 1 .667.667v6.666a.666.666 0 1 1-1.333 0v-4.78L8.47 16.75a.667.667 0 0 1-.88.056l-.062-.056a.667.667 0 0 1 0-.942L18 5.333h-5.333a.667.667 0 1 1 0-1.333h6.666Z"
                fill="#043B7C"></path>
            </svg>
          </div>
        </div>
        <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
          <textarea maxlength="3000" [(ngModel)]="notesData" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="1"> </textarea>
        </mat-form-field>
      </div>
      <div class="button-wrapper">
        <div class="label-input-labelwrapper">
          <button id="btnSave" type="button" mat-flat-button (click)="onaddNotes()" [disabled]="notesData.trim().length < 1 || notesData.trim().length > 3000"
            class="mat-focus-indicator ig2 button button--primary mat-flat-button mat-button-base">
            <span class="save-btn-wrapper" *ngIf="isSaving"><div class="save-button-loader"></div></span>{{ isSaving == true ? 'Adding Note' : 'Add Note' }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
