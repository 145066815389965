<article class="dialog__header">
  <h3 mat-dialog-title>{{data.heading}}</h3>
  <!-- <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" /> -->
</article>
<mat-dialog-content class="mat-typography ignite-two">
  <p > {{data.message}} </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close
      class="ig2 button button--secondary button--small mat-button mat-button-base"
      (click)="close()"> No </button>
  <button mat-flat-button
      class="ig2 button button--primary button--small mat-button mat-button-base" (click)="SaveDiscard()"
      cdkFocusInitial> Yes </button>
</mat-dialog-actions>
