import { InjectionToken } from '@angular/core';
import { TaskBasicInfoDataModel } from '../models/taskBasicInfoDataModel';
// tslint:disable-next-line:no-shadowed-variable
export interface PopoutData<PopoutDataType> {
  modalName: string;
  basicTaskInfo: TaskBasicInfoDataModel
  data: PopoutDataType;
}

export interface NotesPopoutData {
  notes: string,
}

export interface PhraseValuePopoutData {
  documentId: number;
  documentTemplateId: number;
  statusId: number;
  documentName: string;
}

export type PopoutDataType = NotesPopoutData | PhraseValuePopoutData;

export const POPOUT_MODAL_DATA = new InjectionToken<PopoutData<PopoutDataType>>('POPOUT_MODAL_DATA');

export enum PopoutModalName {
  'taskNotes' = 'TASK_NOTES',
  'phraseValues' = 'PHRASE_VALUES'
}

export let POPOUT_MODALS: any = { };

