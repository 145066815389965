import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "../environments/environment";
import { Observable, map } from "rxjs";
import { CreateEmailNote } from "../models/create-note";

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  readonly GTbaseUrl = `${environment.genericTaskBase.apiUrl}`;
  readonly EmailbaseUrl = `${environment.Email.apiUrl}`;


  constructor(private httpClient: HttpClient) { }

  public saveNotes(taskId: string, body: { Notes: string }): Observable<{noteId: string}> {
    const url = `${this.GTbaseUrl}/fileutility/v1/task/${taskId}/notes`;
    return this.httpClient.post<{noteId: string}>(url, body);
  }
  public saveEmailNotes(emailId: any, note: CreateEmailNote): Observable<CreateEmailNote> {
    const url = `${this.EmailbaseUrl}/v1/email/${emailId}/notes`;
    return this.httpClient.post<CreateEmailNote>(url, note).pipe(
      map((noteData: CreateEmailNote) => {
        return noteData;
      })
    );
  }
  public getprocessdetails(id:any) {
    return this.httpClient.get(`${environment.igps.apiUrl}/prdprocess/v1/id/${id}`);
  }
}
