import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetTaskBasicInfo, NavigateBackEventConfirm, NavigateBackEventStart, NavigateBackEventWait, TaskBasicInfoEvent } from '@igps/file-info-header';
import { FaIconsRegistry, faDocumentView, faFileDoc, faFileDocx, faFileJpeg, faFileJpg, faFilePdf, faFilePng, faFileTif, faFileTiff, faFileXls, faFileXlsx, faKebab, faMagnifyingGlassPlus, faMail, faPlusCircle, faSearch, faTrash, faAttachment, faInfo } from '@ui-core/icon';
import { ToastComponent } from '@ui-core/toast';
import { BehaviorSubject, Subscription } from 'rxjs';
import { WarningDialogComponent } from './components/notes/warning-dialog/warning-dialog.component';
import { PopoutService } from './services/popout.service';
import { PopoutModalName } from './services/popout.tokens';
import { Source, TaskBasicInfoDataModel } from './models/taskBasicInfoDataModel';

export const showSpinnerEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
export const showToastEvent: EventEmitter<{ message: string; isError: boolean }> = new EventEmitter<{ message: string; isError: boolean }>();
export const closeWarningEvent: EventEmitter<void> = new EventEmitter<void>();

@Component({
  selector: 'app-client-processor-taskdetail',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  apiCallsCount: BehaviorSubject<number> = new BehaviorSubject(0);
  isError!: boolean;
  errorMessage!: string;
  @ViewChild(ToastComponent) toaster!: ToastComponent;

  spinnerSubscription$!: Subscription;
  toastSubscription$!: Subscription;
  navigateBackEventStartSubscription$!: Subscription;

  basicTaskInfo!: TaskBasicInfoDataModel;
  taskInformationSubscription$!: Subscription;
  isIgniteTask: boolean = false;

  constructor(private faIconsRegistry: FaIconsRegistry, private popoutService: PopoutService, private dialog: MatDialog, private zone: NgZone, private cdref: ChangeDetectorRef) {
    this.faIconsRegistry.registerIcons([faFileDoc, faFileDocx, faFileJpeg, faFileJpg, faFilePdf, faFilePng, faFileTif, faFileTiff, faFileXls, faFileXlsx, faMail, faKebab, faTrash, faDocumentView, faSearch, faMagnifyingGlassPlus, faPlusCircle, faAttachment, faInfo]);
  }

  ngOnDestroy(): void {
    this.spinnerSubscription$?.unsubscribe();
    this.toastSubscription$?.unsubscribe();
    this.navigateBackEventStartSubscription$?.unsubscribe();
    this.taskInformationSubscription$?.unsubscribe();
  }

  ngOnInit(): void {
    this.spinnerSubscription$ = showSpinnerEvent.subscribe(data => {
      this.zone.run(() => {
        if (data) {
          this.apiCallsCount.next(this.apiCallsCount.value + 1);
        }
        else {
          this.apiCallsCount.next(this.apiCallsCount.value - 1);
        }
      });
    });
    this.toastSubscription$ = showToastEvent.subscribe(data => {
      this.zone.run(() => {
        this.openToast(data.message, data.isError);
      });
    });
    debugger;
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe(data => {
      this.zone.run(() => {
        if (this.basicTaskInfo?.id !== data.taskinfo?.id) {
          this.basicTaskInfo = data.taskinfo;
          if (this.basicTaskInfo.sourceId === Source.Ignite) {
            this.isIgniteTask = true;
            this.cdref.detectChanges();
          }
        }
      });
    });
    if (!this.basicTaskInfo) {
      this.zone.run(() => {
        this.basicTaskInfo = GetTaskBasicInfo();
        if (this.basicTaskInfo) {
          if (this.basicTaskInfo.sourceId === Source.Ignite) {
            this.isIgniteTask = true;
            this.cdref.detectChanges();
          }
        }
      });
    }
    this.navigateBackEventStartSubscription$ = NavigateBackEventStart.subscribe(() => {
      let isWarningOpened: boolean = false;
      if (this.popoutService.isPopoutWindowOpen(PopoutModalName.taskNotes)) {
        NavigateBackEventWait.emit();
        isWarningOpened = true;
        this.openWarning(PopoutModalName.taskNotes);
      }
      if (this.popoutService.isPopoutWindowOpen(PopoutModalName.phraseValues)) {
        NavigateBackEventWait.emit();
        isWarningOpened = true;
        this.openWarning(PopoutModalName.phraseValues);
      }
      if (!isWarningOpened) {
        NavigateBackEventConfirm.emit();
      }
    });
  }

  openToast(message: string, isError: boolean = false) {
    this.isError = isError;
    this.errorMessage = message;
    this.toaster.toastDuration = 5000;
    this.toaster.toasterTimeout();
    this.cdref.detectChanges();
    setTimeout(() => {
      this.cdref.detectChanges();
    }, 5100);
  }

  openWarning(popoutName: string) {
    if (popoutName === PopoutModalName.taskNotes) {
      this.zone.run(() => {
        const dialogRef = this.dialog.open(WarningDialogComponent, {
          panelClass: ['ig2', 'mat-dialog-container-small'],
          data: {
            heading: "Warning",
            modalName: PopoutModalName.taskNotes,
            message: "Do you want to add a note?"
          },
          width: '500px',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {
          this.cdref.detectChanges();
        });
      })
    }
    if (popoutName === PopoutModalName.phraseValues) {
      this.zone.run(() => {
        const dialogRef = this.dialog.open(WarningDialogComponent, {
          panelClass: ['ig2', 'mat-dialog-container-small'],
          data: {
            heading: "Warning",
            modalName: PopoutModalName.phraseValues,
            message: "Do you want to discard the phrase value changes?"
          },
          width: '500px',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {
          this.cdref.detectChanges();
        });
      })
    }
  }


}
