import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { showSpinnerEvent, showToastEvent } from '../../../app.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentService } from '../../../services/document.service';
import { DocumentDetails } from '../../../models/documentDetails';
import { getTimezone, timezone$ } from '@igps/client-root-config';
@Component({
  selector: 'app-template-detail-popup',
  templateUrl: './template-detail-popup.component.html',
  styleUrls: ['./template-detail-popup.component.scss']
})
export class TemplateDetailPopupComponent implements OnInit, OnDestroy {
  documentID: number = 0;
  fileID: number = 0;
  showSpinner: boolean = false;
  document!:DocumentDetails;
  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  constructor(public dialogRef: MatDialogRef<TemplateDetailPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private documentService: DocumentService) { }
  ngOnDestroy(): void {
    this.timezoneSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
    });
    this.documentID = this.data.documentID;
    this.fileID = this.data.fileID;
    this.getDocumentDetails();
  }
  private getDocumentDetails() {
    showSpinnerEvent.emit(true);
    this.documentService.getDocumentDetails(this.fileID, this.documentID)
      .pipe(finalize(() => showSpinnerEvent.emit(false)))
      .subscribe({
        next: (result) => {
          this.document = result;
        },
        error: (error: HttpErrorResponse) => {
          showToastEvent.emit({
            message: error?.error.Detail,
            isError: true,
          });
        }
      });
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
