<div *ngIf='document!=null'>
  <article class="dialog__header mat-dialog-title">
    <h3 mat-dialog-title> Document Template Details </h3>
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
  </article>

  <mat-dialog-content class="mat-typography mat-dialog-content">
    <div class="view-documents-text">
      <span>View document details below.</span>
    </div>
    <div class="main-content">
      <!-- LEFT COLUMN -->
      <div class="left-column">
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Template Region</span>
          </div>
          <div>
            <span>{{document.templateInformation.templateRegion.name}}</span>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Template Region ID</span>
          </div>
          <div>
            <span>{{document.templateInformation.templateRegion.id}}</span>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Template Type</span>
          </div>
          <div>
            <span>{{document.documentType.description}}</span>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Template Code</span>
          </div>
          <div>
            <span>{{document.templateInformation.name}}</span>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Friendly Document Name</span>
          </div>
          <div>
            <span>{{document.friendlyDocumentName==null
              || document.friendlyDocumentName==''
              || document.friendlyDocumentName==""
              ?'NA':document.friendlyDocumentName}}
            </span>
          </div>
        </div>
      </div>
      <!-- RIGHT COLUMN -->
      <div class="right-column">
        <!-- <div class="doc-div-template-with-border">
          <div class="doc-label-template">
            <span>Document Name</span>
          </div>
          <div>
            <lib-icon name="document_view">
              <span class="lib-icon-template">{{document.name}}</span>
            </lib-icon>
          </div>
        </div> -->
        <div class="doc-div">
          <div class="doc-name-label">
            <span>Document Name</span>
          </div>
          <div class="doc-name">
            <div class="icon">
              <lib-icon name="document_view">
              </lib-icon>
            </div>
            <div class="name">
              <p>{{document.name}}</p>
            </div>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Created</span>
          </div>
          <div>
            <span>{{document.createdDate | convertTime: "us/pacific": timezone.value : "default" : true : true}} 
            </span>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Status</span>
          </div>
          <div>
            <span>{{document.status.description}}</span>
          </div>
        </div>
        <div class="doc-div-template">
          <div class="doc-label-template">
            <span>Document ID</span>
          </div>
          <div>
            <span>{{document.id}}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mat-dialog-actions">
    <button class="ig2 button button--clear button--small" mat-button mat-dialog-close>Cancel</button>
    <button class="ig2 button button--primary button--small" mat-button (click)="closeDialog()">
      Close
    </button>
  </mat-dialog-actions>
</div>