export enum DocumentExtensionEnum {
    PDF = 'PDF',
    JPG = 'JPG',
    TIF = 'TIF',
    DOC = 'DOC',
    XLS = 'XLS',
    JPEG = 'JPEG',
    DOCX = 'DOCX',
    TIFF = 'TIFF',
    XLSX = 'XLSX',
}
