import { FilterSelectModule } from '@igps/filter-select';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotesComponent } from './components/notes/notes.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddNotesComponent } from './components/notes/add-notes/add-notes.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TaskInformationComponent } from './components/task-information/task-information.component';
import { HttpInterceptor } from './interceptor/header.interceptor';
import { POPOUT_MODAL_DATA } from './services/popout.tokens';
import { PopoutService } from './services/popout.service';
import { WarningDialogComponent } from './components/notes/warning-dialog/warning-dialog.component';
import { PortalWrapperComponent } from './components/notes/portal-wrapper/portal-wrapper.component';
import { BrowserModule } from '@angular/platform-browser';
import { ToastModule } from '@ui-core/toast';
import { BadgeModule } from '@ui-core/badge';
import { SpinnerModule } from '@igps/spinner';
import { DocumentDetailPopupComponent } from './components/doc-repo/document-detail-popup/document-detail-popup.component';
import { IconModule } from '@ui-core/icon';
import { DocUploadComponent } from './components/doc-repo/doc-upload/doc-upload.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IpsDocFileDirective } from './directives/ips.docfile.directive';
import { PhraseValuePopupComponent } from './components/doc-repo/phrase-value-popup/phrase-value-popup.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddDocumentComponent } from './components/doc-repo/add-document/add-document.component';
import { BrowseDocRepoPopupComponent } from './components/doc-repo/browse-doc-repo-popup/browse-doc-repo-popup.component';
import { TemplateDetailPopupComponent } from './components/doc-repo/template-detail-popup/template-detail-popup.component';
import { DialogOverviewExampleDialog } from './components/doc-repo/remove-document/removeDocument-popup';
import { ImageDetailPopupComponent } from './components/doc-repo/image-detail-popup/image-detail-popup.component';
import { DatePipe } from '@angular/common';
import { PipeModule, ConvertTimePipe } from '@igps/pipe';
import { AttachEmailPopupComponent } from './components/attach-email-popup/attach-email-popup.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    NotesComponent,
    AddNotesComponent,
    TaskInformationComponent,
    PortalWrapperComponent,
    WarningDialogComponent,
    DocumentDetailPopupComponent,
    DocUploadComponent,
    IpsDocFileDirective,
    AddDocumentComponent,
    PhraseValuePopupComponent,
    BrowseDocRepoPopupComponent,
    TemplateDetailPopupComponent,
    DialogOverviewExampleDialog,
    ImageDetailPopupComponent,
    AttachEmailPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    IconModule,
    SpinnerModule,
    ToastModule,
    MatTabsModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    FilterSelectModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatRadioModule,
    HttpClientModule,
    FilterSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTooltipModule,
    PipeModule,
    BadgeModule,
    MatFormFieldModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: POPOUT_MODAL_DATA,
      useClass: PopoutService,
    },
    IpsDocFileDirective,
    DatePipe,
    ConvertTimePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
