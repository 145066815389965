<div mat-dialog-title class="mat-dialog-title">
  <div>Add From Document Repository</div>
  <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</div>
<mat-dialog-content class="mat-typography mdc">
  <div class="view-documents-text">
    <span>Select documents to add to task.</span>
  </div>
  <section class="mdc-section">
    <table class="mdc-table" matSort (matSortChange)="sortData($event)" [matSortActive]="'documentName'" [matSortDirection]="'asc'" matSortDisableClear>
      <thead>
        <tr>
          <th>Select</th>
          <th mat-sort-header="documentName">Document</th>
          <!-- <th>Tasks</th> -->
          <th>Size</th>
          <th>Time Uploaded</th>
          <th>Uploaded By</th>
          <th>Preview</th>
        </tr>
      </thead>
      <tbody *ngIf="sortedData && sortedData.length>0">
        <tr *ngFor="let item of sortedData;let documentSelectedIndex = index" [ngClass]="selectedRows.indexOf(documentSelectedIndex) !== -1 ? 'row-selected' : 'ng-star-inserted'">
          <td>
            <mat-checkbox class="checkbox" [checked]="item.isIpsLinked" [disabled]="item.isIgnite" (change)="onChange($event, item, documentSelectedIndex)"></mat-checkbox>
          </td>
          <td class="va">
            <span *ngIf="item.documentExtensionType != 'Created'">
              <lib-icon [tooltip]="item.documentExtensionType.split('.')[1]" [name]="item.documentExtensionType.split('.')[item.documentExtensionType.split('.').length - 1].toLowerCase() === 'msg' ? 'mail' : 'file_'+ item.documentExtensionType.split('.')[item.documentExtensionType.split('.').length - 1].toLowerCase()">
              </lib-icon>
            </span>
            {{item.documentName}}
          </td>
          <!-- <td>{{item.documentName}}</td> -->
          <td>{{item.fileSize > 0 ? item.fileSize +' MB' : '' }}</td>
          <td>{{item.createdDate | convertTime: "us/pacific": timezone.value : "default" : true : true }}</td>
          <td>{{item.uploadedBy}}</td>
          <td>
            <button mat-flat-button class="ig2 button button--secondary button--xsmall va" 
              *ngIf="item.documentExtensionType !== 'Created'"
              (click)="previewImage(item)">
              <lib-icon class="cursor__pointer ig-input-field__icon-after icon-va" name="magnifying_glass_plus" size="small"></lib-icon>
              <span class="hidden-small"> Preview</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</mat-dialog-content>

<mat-dialog-actions>
  <section class="footer">
    <button mat-flat-button mat-dialog-close type="button" class="mat-focus-indicator ig2 button button--clear mat-flat-button mat-button-base btn-cancel">Cancel</button>
    <button type="submit" mat-flat-button class="btn-submit ig2 button button--primary button--small mat-button mat-button-base btn-submit" cdkFocusInitial (click)="onSubmit()" [disabled]="isSubmitDisabled">Submit</button>
  </section>
</mat-dialog-actions>